export default [
  {
    path: "/insurance-payments",
    name: "module.QUIE.RICQ",
    meta: {
      auth: true,
      module: "QUIE",
      submodule: "RICQ",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "insurance-payments" */ "@/views/insurance-payments/index.vue"
      ),
  },
  {
    path: "/insurance-payments/:id/edit",
    name: "insurance_payments.edit",
    meta: {
      auth: true,
      module: "QUIE",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-payments" */ "@/views/insurance-payments/edit.vue"
      ),
  },
  {
    path: "/insurance-payments-consolidation",
    name: "module.QUIE.CONS",
    meta: {
      auth: true,
      module: "QUIE",
      submodule: "CONS",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-payments" */ "@/views/insurance-payments/consolidation.vue"
      ),
  },
  {
    path: "/insurance-payments-deadlines",
    name: "module.QUIE.VSCA",
    meta: {
      auth: true,
      module: "QUIE",
      submodule: "VSCA",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-payments" */ "@/views/insurance-payments/deadlines.vue"
      ),
  },
];
