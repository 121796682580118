import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/book_entries";
export default {
  create(queryString) {
    // get the create form
    return queryString
      ? Repository.get(`${resource}/create/${queryString}`)
      : Repository.get(`${resource}/create`);
  },
  destroy(id) {
    // delete the item
    return Repository.delete(`${resource}/${id}`);
  },
  edit(id, queryString) {
    // get the edit form
    return queryString
      ? Repository.get(`${resource}/edit/${queryString}`)
      : Repository.get(`${resource}/${id}/edit`);
  },
  filter_update(payload, queryString) {
    // es: byAttribute[id]=1,2,3,4,5
    return queryString
      ? Repository.put(`${resource}/filter/update?${queryString}`, payload)
      : Repository.put(`${resource}/filter/update`, payload);
  },
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  pivot_store(id, relation, payload) {
    return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
  },
  pivot_update(id, relation, payload) {
    return Repository.put(`${resource}/${id}/pivot/${relation}`, payload);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  report_rendiconto(queryString, payload) {
    // get the list
    return queryString
      ? Repository.post(`${resource}/report?${queryString}`, payload)
      : Repository.post(`${resource}/report`, payload);
  },
  summary(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}/summary?${queryString}`)
      : Repository.get(`${resource}/summary`);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  validate(field, payload) {
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  // Broker statements
  generate_broker_statements(payload) {
    // save the item
    return Repository.post(`${resource}/statements`, payload);
  },
  // Report EXPOMON (CSV)
  report(payload) {
    // save the item
    return Repository.post(`${resource}/export`, payload);
  },
  // Report Custom (CSV)
  report_custom(payload, code) {
    // save the item
    return Repository.post(`${resource}/${code}`, payload);
  },
  bulk_store(payload) {
    return Repository.post(`${resource}/bulk/store`, payload);
  },
  bulk_update(payload) {
    return Repository.put(`${resource}/bulk/update`, payload);
  },
};
