import FormMixin from "@/mixins/FormMixin";

export default {
  data: () => ({}),
  mixins: [FormMixin],
  computed: {},
  methods: {
    getResources(promises) {
      return (
        Promise.all(promises)
          // .then(() => {
          //   resolve();
          // })
          // .catch((error) => {
          //   reject(error);
          // })
          .finally(() => {
            this.unloadMsg();
            this.isLoading = false;
          })
      );
    },
    // prepareResource(resource) {
    //   console.log(`%c${resource}`, "color: green");
    //   return this.$store
    //     .dispatch(resource)
    //     .then(() => {
    //       console.info(`background data "${resource}" fetched correctly`);
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({ preset: "error", text: errMsg });
    //     })
    //     .finally(() => {
    //       this.unloadMsg();
    //       this.isLoading = false;
    //     });
    // },
    // getResources(resource) {
    //   console.log(`%c${resource}`, "color: green");
    //   this.$store
    //     .dispatch(resource)
    //     .then(() => {
    //       console.info(`background data "${resource}" fetched correctly`);
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({ preset: "error", text: errMsg });
    //     })
    //     .finally(() => {
    //       this.unloadMsg();
    //       this.isLoading = false;
    //     });
    // },
    hasLoadedResource(resource) {
      console.log(`%cI am a computed for ${resource}`, "color: pink");
      return this.$store.state.auth[resource].length ? true : false;
    },
  },
};
