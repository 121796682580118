import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/registry_groups";
export default {
  create() {
    // get the create form
    return Repository.get(`${resource}/create`);
  },
  edit(id) {
    // get the edit form
    return Repository.get(`${resource}/${id}/edit`);
  },
  validate(field, payload) {
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    // delete the item
    return Repository.delete(`${resource}/${id}`);
  },
  destroy_multiple(payload) {
    // delete a list of item ids, where payload = { ids: [1,2,...,N] }
    return Repository.delete(`${resource}/delete`, payload);
  },
  pivot_form(id, relation) {
    // get the create pivot form
    return Repository.get(`${resource}/${id}/pivot/${relation}`);
  },
  pivot_destroy(id, relation, payload) {
    // delete the related data ids , where payload = { `${relation}`: [1,2,...,N] }
    // eg: if relation is registry, payload = { registry: [1,2,...,N] }
    return Repository.delete(`${resource}/${id}/pivot/${relation}`, {
      data: payload,
    });
  },
  pivot_store(id, relation, payload) {
    // store the related data, where payload = {
    //   "registry": {
    //     "1": {
    //       "primary": "Y",
    //       "is_coordinator": "Y",
    //       "communication_type": 2
    //     },
    //   }
    // }
    // (se mando in POST 1 solo record, insert or update solo su quello)
    return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
  },
  pivot_update(id, relation, payload) {
    // update the related data
    // (se mando in PUT 1 solo record, insert or update MA toglie tutti gli altri)
    return Repository.put(`${resource}/${id}/pivot/${relation}`, payload);
  },
  // Report EXPOMON (CSV)
  report(payload) {
    // save the item
    return Repository.post(`${resource}/export`, payload);
  },
};
