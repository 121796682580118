const queue = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    QUEUE_NOTIFY(state, queue) {
      // ****METHOD 1.0******
      let newElement = queue;
      let tempBreadcrumb = [...state.items].reverse();

      if (tempBreadcrumb.length == 0) {
        state.items.push(queue);
      }

      for (let index = 0; index < tempBreadcrumb.length; index++) {
        let b = tempBreadcrumb[index];
        let rIndex = tempBreadcrumb.length - index - 1;
        // il vecchio elemento va tolto
        if (newElement.level < b.level) {
          state.items.splice(rIndex, 1);
        }
        // il nuovo elemento va aggiunto
        if (newElement.level > b.level) {
          state.items.push(newElement);
          break;
        }
        // il nuovo elemento deve sostituirsi
        if (newElement.level == b.level) {
          state.items.splice(rIndex, 1, newElement);
          break;
        }
      }
      // ****METHOD 2.0******
      // let temp_queue = state.items;

      // if (temp_queue.length > 0) {
      //   const diff = (num1, num2) => num1 - num2;
      //   const delta = diff(
      //     temp_queue[temp_queue.length - 1].level,
      //     queue.level
      //   );
      //   const checksection =
      //     temp_queue[temp_queue.length - 1].level / queue.level ? true : false;
      //   if (delta < 0) {
      //     temp_queue.push(queue);
      //   } else if (delta == 0) {
      //     if (checksection) {
      //       temp_queue.pop();
      //       temp_queue.push(queue);
      //     } else temp_queue = [queue];
      //     state.items = temp_queue;
      //   } else {
      //     const index = temp_queue.findIndex(
      //       (element) => element.level === queue.level
      //     );
      //     temp_queue[index] = queue;
      //     temp_queue.splice(temp_queue.length - delta, temp_queue.length);
      //     state.items = temp_queue;
      //   }
      // } else {
      //   state.items.push(queue);
      // }
    },
    QUEUE_DENOTIFY(state, queue) {
      state.items = state.items.filter((e) => e.level > queue.level);
    },
    QUEUE_EMPTY(state) {
      state.items = [];
    },
    QUEUE_POP(state) {
      state.items.pop();
    },
    QUEUE_PUSH(state, item) {
      state.items.push(item);
    },
  },
  actions: {
    notifyQueue({ commit }, obj) {
      // console.debug("queue action QUEUE_NOTIFY", obj);
      commit("QUEUE_NOTIFY", obj);
    },
    popQueue({ commit }) {
      commit("QUEUE_POP");
    },
    pushQueue({ commit }, item) {
      commit("QUEUE_POP"), item;
    },
    emptyQueue({ commit }) {
      // console.debug("queue action emptyQueue");
      commit("QUEUE_EMPTY");
    },
    denotifyQueue({ commit }, obj) {
      // console.debug("queue action emptyQueue");
      commit("QUEUE_DENOTYFY", obj);
    },
  },
  getters: {
    getQueue: (state) => {
      // console.debug("queue getter getQueue", state.items);
      return state.items;
    },
  },
};

export default queue;
