export default [
  {
    path: "/book-entries",
    name: "module.CONT.FCAS",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "FCAS",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "book_entries" */ "@/views/accounting/book-entries/index.vue"
      ),
  },
  {
    path: "/book-entries-create",
    name: "book_entries.create",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "book_entries" */ "@/views/accounting/book-entries/create.vue"
      ),
  },
  {
    path: "/book-entries-edit",
    name: "book_entries.edit",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "book_entries" */ "@/views/accounting/book-entries/edit.vue"
      ),
  },
  {
    path: "/deferred",
    name: "module.CONT.SOSP",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "SOSP",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "deferred" */ "@/views/accounting/deferred/index.vue"
      ),
  },
  {
    path: "/deposited",
    name: "book_entries.index2",
    meta: {
      auth: true,
      // hasAccess: true,
      module: "CONT",
      submodule: "",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "deferred" */ "@/views/accounting/deposit/index.vue"
      ),
  },
  {
    path: "/cooperators",
    name: "book_entries.index3",
    meta: {
      auth: true,
      // hasAccess: true,
      module: "CONT",
      submodule: "",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "deferred" */ "@/views/accounting/cooperators/index.vue"
      ),
  },
  {
    path: "/various-accounting",
    name: "module.CONT.MOVV",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "MOVV",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "various-accounting" */ "@/views/accounting/various-accounting/index.vue"
      ),
  },

  {
    path: "/accounting-book",
    name: "module.CONT.FQUA",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "FQUA",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accounting-book" */ "@/views/accounting/accounting-book/index.vue"
      ),
  },
  {
    path: "/stats",
    name: "module.CONT.STAT",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "STAT",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "stats" */ "@/views/accounting/stats/index.vue"
      ),
  },
  {
    path: "/accounting-prod",
    name: "module.CONT.CONP",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "CONP",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accounting-prod-management" */ "@/views/accounting/accounting-prod/Management.vue"
      ),
  },
  {
    path: "/accounting-prod-salesmen",
    name: "salesmen.index",
    meta: {
      auth: true,
      module: "module.CONT.CONP",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "salesmen" */ "@/views/accounting/accounting-prod/salesmen/index.vue"
      ),
  },
  {
    path: "/accounting-prod-broker-statements",
    name: "broker-statements.index",
    meta: {
      auth: true,
      module: "module.CONT.CONP",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "salesmen" */ "@/views/accounting/accounting-prod/broker-statements/index.vue"
      ),
  },
  {
    path: "/accounting-insurer",
    name: "module.CONT.CONC",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "CONC",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accounting-insurer" */ "@/views/accounting/accounting-insurer/index.vue"
      ),
  },
  {
    path: "/coinsurance",
    name: "module.CONT.COAS",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "COAS",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "coinsurance" */ "@/views/accounting/coinsurance/index.vue"
      ),
  },
  {
    path: "/accounting-agency",
    name: "module.CONT.CONA",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "CONA",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accounting-agency" */ "@/views/accounting/accounting-agency/Management.vue"
      ),
  },
  {
    path: "/accounting-agency-books",
    name: "agency_books.index",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_books" */ "@/views/accounting/accounting-agency/agency-books/index.vue"
      ),
  },
  {
    path: "/accounting-agency-books/:id/edit",
    name: "agency_books.edit",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_books" */ "@/views/accounting/accounting-agency/agency-books/edit.vue"
      ),
  },
  {
    path: "/accounting-agency-books-create",
    name: "agency_books.create",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_books" */ "@/views/accounting/accounting-agency/agency-books/create.vue"
      ),
  },
  {
    path: "/accounting-agency-contacts",
    name: "agency_contacts.index1",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_contacts" */ "@/views/accounting/accounting-agency/agency-contacts/index.vue"
      ),
  },
  {
    path: "/accounting-agency-contacts/:id/edit",
    name: "agency_contacts.edit1",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_contacts" */ "@/views/accounting/accounting-agency/agency-contacts/edit.vue"
      ),
  },
  {
    path: "/accounting-agency-contacts-create",
    name: "agency_contacts.create1",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_contacts" */ "@/views/accounting/accounting-agency/agency-contacts/create.vue"
      ),
  },
  {
    path: "/accounting-payment-references",
    name: "payment_references.index",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "payment_references" */ "@/views/accounting/accounting-agency/payment-references/index.vue"
      ),
  },
  {
    path: "/accounting-payment-references/:id/edit",
    name: "payment_references.edit",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "payment_references" */ "@/views/accounting/accounting-agency/payment-references/edit.vue"
      ),
  },
  {
    path: "/accounting-payment-references-create",
    name: "payment_references.create",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "payment_references" */ "@/views/accounting/accounting-agency/payment-references/create.vue"
      ),
  },
  {
    path: "/bank-statement",
    name: "module.CONT.ESCO",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "ESCO",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "bank-statement" */ "@/views/accounting/bank-statement/index.vue"
      ),
  },
  {
    path: "/advance-payment",
    name: "book_entries.index",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    // id: "module.A",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "advance-payment" */ "@/views/accounting/advance-payment/Management.vue"
      ),
  },
  {
    path: "/unsolved-payment",
    name: "unsolved_payments.index",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "unsolved_payments" */ "@/views/accounting/advance-payment/unsolved-payments/index.vue"
      ),
  },
  {
    path: "/unsolved-payment-create",
    name: "unsolved_payments.create",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "unsolved_payments" */ "@/views/accounting/advance-payment/unsolved-payments/create.vue"
      ),
  },
  {
    path: "/unsolved-payment/:id/unsolved",
    name: "unsolved_payments.edit",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "unsolved_payments" */ "@/views/accounting/advance-payment/unsolved-payments/edit.vue"
      ),
  },
  {
    path: "/cash-entries-default",
    name: "cash_entries.index0",
    meta: {
      auth: true,
      hasAccess: true,
      module: "CONT",
    },
    component: () =>
      import(
        /* webpackChunkName: "cash_entries" */ "@/views/accounting/cash-entries/Management.vue"
      ),
  },
  {
    path: "/cash-entries-default-create",
    name: "cash_entries.create0",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cash_entries" */ "@/views/accounting/cash-entries/book-entries/create.vue"
      ),
  },
  {
    path: "/cash-entries-default/:id/edit",
    name: "cash_entries.edit0",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cash_entries" */ "@/views/accounting/cash-entries/book-entries/edit.vue"
      ),
  },
  {
    path: "/cash-entries-deferred-create",
    name: "cash_entries.create1",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cash_entries" */ "@/views/accounting/cash-entries/deferred/create.vue"
      ),
  },
  {
    path: "/cash-entries-deferred/:id/edit",
    name: "cash_entries.edit1",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cash_entries" */ "@/views/accounting/cash-entries/deferred/edit.vue"
      ),
  },
  {
    path: "/cash-entries-deposit-create",
    name: "cash_entries.create2",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cash_entries" */ "@/views/accounting/cash-entries/deposit/create.vue"
      ),
  },
  {
    path: "/cash-entries-deposit/:id/edit",
    name: "cash_entries.edit2",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cash_entries" */ "@/views/accounting/cash-entries/deposit/edit.vue"
      ),
  },
  {
    path: "/accounting-reports",
    name: "module.CONT.REPO",
    meta: {
      auth: true,
      module: "CONT",
      submodule: "REPO",
    },
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/accounting/reports.vue"
      ),
  },
];
