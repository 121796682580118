import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/address_books";
export default {
  create() {
    // get the create form
    return Repository.get(`${resource}/create`);
  },
  edit(id) {
    // get the edit form
    return Repository.get(`${resource}/${id}/edit`);
  },
  validate(field, payload) {
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    // delete the item
    return Repository.delete(`${resource}/${id}`);
  },
  pivot_store(id, relation, payload) {
    return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
  },
  pivot_update(id, relation, payload) {
    return Repository.put(`${resource}/${id}/pivot/${relation}`, payload);
  },
};
