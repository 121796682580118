import { mapGetters } from "vuex";
import { prepareRules } from "@/utils/validation";
import {
  getFormFieldsKeys,
  setFormValues,
  getResource,
  getInputType,
  nullifyObjectProps,
} from "@/utils/forms";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { getDictionary } from "@/utils/dictionary";
import { removeEmpty } from "@/utils/forms";
import { canVerb, canModule } from "@/utils/permissions";

export default {
  data: () => ({
    isLoading: true,
    form: {},
    beForm: {},
    beRules: {},
    mandatoryFields: {},
    defaultLabels: {},
    labels: {},
    customInputs: {}, // used to add custom inputs dynamically
    ok: false, // status flag if error then false
    errorMessage: "", // error message to display
    version: "",
    intervalid: null, // setInterval to loop over messages
    iteration: 0,
    message: "",
    messages: [
      "Questa operazione potrebbe impiegare alcuni secondi...",
      "Attendere prego...",
      "Operazione in corso...",
    ],
    delay: 10000,
  }),
  methods: {
    getDictionary,
    getInputType,
    removeEmpty,
    canVerb,
    canModule,
    initMandatoryFields(repository) {
      const mandatoryRules = ["required", "AttributeRequiredRule"];
      if (!this.mandatoryFields[repository]) {
        // init the object for repository
        this.mandatoryFields[repository] = {};
      }
      // general method to init a list of mandatory fields taken from beRules
      if (
        !this.beRules[repository] ||
        !Object.keys(this.beRules[repository]).length
      )
        return;
      for (const [field, rules] of Object.entries(this.beRules[repository])) {
        // if (rules.includes("required")) {
        // array intersection
        if (mandatoryRules.filter((value) => rules.includes(value)).length) {
          // add field
          this.mandatoryFields[repository][field] = null;
        }
      }
    },
    setMandatoryValues(repository, payload) {
      for (const key in payload) {
        if (
          payload[key] === null ||
          payload[key] === undefined ||
          payload[key] === ""
        ) {
          if (typeof this.beForm[repository][key] === "object") {
            // if (
            //   ![null, undefined].includes(this.beForm[repository][key].value)
            // ) {
            //   payload[key] = this.beForm[repository][key].value;
            // }
            if (
              this.beForm[repository][key] &&
              ![null, undefined].includes(this.beForm[repository][key].value)
            ) {
              payload[key] = this.beForm[repository][key].value;
            }
          } else {
            // eg: title arriva come stringa
            if (![null, undefined].includes(this.beForm[repository][key])) {
              payload[key] = this.beForm[repository][key];
            }
          }
        }
        // if (this.beForm[repository][key].type === "select") {
        //   payload[key] = this.beForm[repository][key].value;
        // }
      }
      // return payload;
    },
    // initLabels(repository) {
    //   const re = /^attribute_(.+)_value/g;
    //   // for (let repository of this.allRepositories) {
    //   // assign defaultLables
    //   let dL =
    //     this.defaultLabels && this.defaultLabels[repository]
    //       ? this.defaultLabels[repository]
    //       : {};
    //   let labs = Object.assign({}, dL);
    //   // if (!this.beForm[repository]) {
    //   // this.labels[repository] = labs;
    //   // }
    //   let beF = this.beForm[repository] || {};
    //   console.log("repository", repository);
    //   console.log("beF", beF);
    //   for (const [key, value] of Object.entries(beF)) {
    //     // CHECK title arriva come stringa e non come Object!!! è un BUG???
    //     // if (["title", "formatted_title"].includes(key)) {
    //     //   if (typeof value === "string") {
    //     //     labs[key] = value;
    //     //     continue;
    //     //   }
    //     // }
    //     if (key.match(re)) {
    //       let name = re.exec(key);
    //       labs[name[1]] = value.label;
    //     } else {
    //       labs[key] = value.label;
    //     }
    //   }
    //   this.labels[repository] = labs;
    //   // }
    //   console.debug("LABELS:", this.labels);
    // },
    initLabels(repository) {
      let dL =
        this.defaultLabels && this.defaultLabels[repository]
          ? this.defaultLabels[repository]
          : {};
      let labs = Object.assign({}, dL);
      let beF = this.beForm[repository] || {};
      // console.log("repository", repository);
      // console.log("beF", beF);
      for (const field in beF) {
        labs[field] = this.getDictionary(field, repository);
      }
      this.labels[repository] = labs;
      // }
      // console.debug("LABELS:", this.labels);
    },
    initDetailCardFormData(repositories = []) {
      // initFormData
      let repos = [];
      repos = repositories.length ? repositories : this.allRepositories || [];
      if (!repos.length) {
        console.info(
          "initDetailCardFormData: missing repositories/allRepositories. Fallback to this.repository"
        );
        repos.push(this.repository);
      }
      // repos.forEach((repository) => {
      for (const repository of repos) {
        this.initLabels(repository);
        this.initMandatoryFields(repository);
      }
      // });
    },
    fetchEditForm(repository, id) {
      const Repo = RepositoryFactory.get(repository);
      // this.isLoading = true;
      return Repo.edit(id)
        .then((response) => {
          const data = response.data;
          this.version = data.version;
          let keys = getFormFieldsKeys(data.fields); //["secret", "user_id"];
          let subKeys;
          switch (repository) {
            case "report":
              // data contiene sia campi normali e un campo "request_input" che è un object contenente più campi...
              this.beForm[repository] = {};
              this.beRules[repository] = {};
              this.$set(this.form, repository, {});
              for (const key of keys) {
                switch (key) {
                  case "request_input":
                    subKeys = getFormFieldsKeys(data.fields[key]);
                    this.beForm[repository][key] = {};
                    this.beRules[repository][key] = {};
                    this.beForm[repository][key] = {};
                    this.$set(this.form[repository], key, {});
                    for (const subKey of subKeys) {
                      this.beForm[repository][key][subKey] =
                        data.fields[key][subKey];
                      this.$set(this.form[repository][key], subKey, null);
                    }
                    break;
                  default:
                    this.beForm[repository][key] = data.fields[key];
                    // this.beRules[repository][key] = data.rules[key];
                    // this.form[repository][key] = null;
                    this.$set(this.form[repository], key, null);
                }
              }
              break;
            default:
              // console.info("API version:", this.version);
              // set beForm eventually merged with with extendedBeForm, if any
              if (
                this.extendedBeForm &&
                Object.keys(this.extendedBeForm).length
              ) {
                this.beForm[repository] = {
                  ...data.fields,
                  ...(this.extendedBeForm || {}),
                };
              } else {
                this.beForm[repository] = data.fields;
              }
              this.beRules[repository] = data.rules;
              this.form[repository] = setFormValues(
                keys,
                this.beForm[repository]
              );

              if (data.entity) {
                this.entity = data.entity
              }
          }
          this.ok = true;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          // showSnackbar({ preset: 'error', text: error });
          this.ok = false;
          this.errorMessage = error;
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    fetchCreateForm(repository) {
      const Repo = RepositoryFactory.get(repository);
      // this.isLoading = true;
      return Repo.create()
        .then((response) => {
          const data = response.data;
          this.version = data.version;
          // console.info("API version:", this.version);
          // console.debug(
          //   `fetchCreateForm fields: ${repository} ${JSON.stringify(
          //     data.fields
          //   )}`
          // );
          // set beForm eventually merged with with extendedBeForm, if any
          if (this.extendedBeForm && Object.keys(this.extendedBeForm).length) {
            this.beForm[repository] = {
              ...data.fields,
              ...(this.extendedBeForm || {}),
            };
          } else {
            this.beForm[repository] = data.fields;
          }
          this.beRules[repository] = data.rules;
          this.ok = true;
        })
        .catch((error) => {
          this.ok = false;
          this.errorMessage = error;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    getRules(field, repository = this.repository) {
      let rules = {};
      if (
        Object.keys(this.beRules[repository]).length &&
        this.beRules[repository][field]
      ) {
        rules = prepareRules(this.beRules[repository][field]);
      }
      return rules;
    },
    // call onBlur to perform server-side validation
    apiValidate(repository, field, observer) {
      // NOTE: in order to successflully set errors on observer refs vids,
      // "vid" MUST equal the field name, eg vid="attribute_NINO_value"
      // because in this.$refs[observer].refs[param].applyResult..., param is bound to vid.
      // and we call: this.$refs[observer].refs[field].applyResult...
      // console.debug(
      //   `apiValidate repository: ${repository}, field: ${field}, observer: ${observer}`
      // );
      const Repo = RepositoryFactory.get(repository);
      //   let errors = {};
      let value = {};
      // let resource = this.beForm[field]['validation']
      let resource = this.beForm[repository][field].validation
        ? getResource(this.beForm[repository][field].validation.path, field)
        : undefined;
      if (!resource) {
        return null;
      }
      let multi = this.beForm[repository][field].validation.multi;
      if (!multi) {
        // value = this.form[field];
        value[field] = this.form[repository][field];
      } else {
        value = this.form[repository];
      }
      Repo.validate(field, value)
        .then(() => {
          // clear errors, if previously set
          this.$refs[observer].refs[field].applyResult({
            errors: [], // array of string errors
            valid: true, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        })
        .catch((error) => {
          this.$refs[observer].setErrors(error.data.errors);
        });
    },
    // call onClick on action button
    validate(repository, field) {
      console.info(`validate repository: ${repository}, field: ${field}`);
      const Repo = RepositoryFactory.get(repository);
      // let value = { field: this.form[repository][field] };
      let payload = {};
      payload[field] = this.form[repository][field];
      // console.debug("payload", payload);
      return Repo.validate(field, payload);
    },
    /**
     * Get a value based on a dot-notation key.
     *
     * @param {Object} obj - Object to be read from
     * @param {[string] || string} keys - Initially, the dot-notation key.
     * @returns {*} - Value sought OR undefined if key does not exist in obj
     */
    getValue(obj, keys) {
      keys = typeof keys === "string" ? keys.split(".") : keys;
      const key = keys.shift();
      // if (obj.hasOwnProperty(key) && keys.length === 0)
      if (Object.prototype.hasOwnProperty.call(obj, key) && keys.length === 0)
        return obj[key];
      // else if (!obj.hasOwnProperty(key)) return undefined;
      else if (!Object.prototype.hasOwnProperty.call(obj, key))
        return undefined;
      else return this.getValue(obj[key], keys);
    },
    /**
     * Set a value based on a dot-notation key.
     *
     * @param {Object} obj
     * @param {[string] || string} keys
     * @param value
     */
    setValue(obj, keys, value) {
      keys = typeof keys === "string" ? keys.split(".") : keys;
      const key = keys.shift();

      if (keys.length === 0) {
        obj[key] = value;
        return;
      }
      // else if (!obj.hasOwnProperty(key))
      else if (!Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = {};
      }

      this.setValue(obj[key], keys, value);
    },
    /**
     *
     * @param {String} form dot notation string
     * @param {String} observer
     * @param {Array} except
     */
    resetForm(form = "form", observer = "observer", except = [], force = []) {
      // set all form values to null
      let s = form.split(".");
      let o = s.reduce((acc, part) => acc && acc[part], this);
      nullifyObjectProps(o, except, force); // o is modified by ref, no need to assign back
      // this[form] = nullifyObjectProps(this[form], except);
      this.resetValidationErrors(observer);
    },
    resetValidationErrors(observer = "observer") {
      this.$nextTick(() => {
        this.$refs[observer] && this.$refs[observer].reset();
      });
    },
    delete(repository, id) {
      const Repo = RepositoryFactory.get(repository);
      return Repo.destroy(id);
    },
    store(repository, form = null) {
      // form can be a custom form
      const Repo = RepositoryFactory.get(repository);
      return Repo.store(removeEmpty(form ? form : this.form[repository]));
    },
    update(repository, id, form = null) {
      // form can be a custom form, or this.form
      const Repo = RepositoryFactory.get(repository);
      return Repo.update(id, removeEmpty(form ? form : this.form[repository]));
    },
    loadMsg() {
      this.message = this.messages[this.iteration];
      this.iteration += 1;
      this.intervalid = setInterval(() => {
        // let messages = [
        //   "Questa operazione potrebbe impiegare alcuni secondi...",
        //   "Attendere prego...",
        //   "Operazione in corso...",
        // ];
        // this.message = messages[this.iteration % messages.length];
        this.message = this.messages[this.iteration % this.messages.length];
        this.iteration += 1;
      }, this.delay);
    },
    unloadMsg() {
      clearInterval(this.intervalid);
    },
    ...mapGetters("auth", ["customAttributes"]),
  },
  computed: {
    msg() {
      return this.message;
    },
  },
  mounted() {},
  beforeMount() {
    // // DEBUG
    // console.debug("BEFORE MOUNT:::::::::::::::::::::::::FormMixin");
    // console.debug(`beforeMount: repository = ${this.repository}`);
    // console.debug(`beforeMount: resource = ${this.resource}`);

    // this.fetch();
    // this.customAttributes.push({ key: 'created_at', label: 'Creato', sortable: true})
    // {
    //   "key": "SURN",
    //   "label": "Cognome",
    //   "value": "Corti",
    //   "is_custom": "N",
    //   "is_search": "Y"
    //   "is_sortable": "Y"
    // }
    if (!this.repository) {
      console.info("FormMixin: missing repository");
      return;
    }
    // TODO: with same logic, prepare the detailFiels by inserting customAttributes
    // NOTE: before that, need to define a data structure for detailFields suitable to allow also Objects to be easily rendered by SmartCard component
    let ca = this.customAttributes()(this.repository);

    // extract only the "key" values
    this.customInputs[this.repository] = ca
      ? ca.filter((e) => e.is_hidden === "N").map((obj) => obj.key)
      : [];
    if (this.customInputs[this.repository].length) {
      // Create the dynamic v-model data property using $set:
      if (!this.form[this.repository]) {
        this.form[this.repository] = {};
      }
      this.customInputs[this.repository].forEach((element) => {
        // NOTE: since input is created and bound at runtime, must use $set:
        this.$set(
          this.form[this.repository],
          `attribute_${element}_value`,
          null
        );
      });
    }
    // console.debug(
    //   `beforeMount: customInputs = ${JSON.stringify(
    //     this.customInputs[this.repository]
    //   )}`
    // );
  },
};
