<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid) ||
          (Object.keys(rules).includes('required_with') && !valid),
      }"
    >
      <b-form-input
        :id="`input-${id}`"
        :name="`input-${id}`"
        :type="type"
        :style="
          type === 'currency' || custom_type === 'currency'
            ? { 'text-align': 'right' }
            : ''
        "
        :maxlength="maxlength"
        v-model="inputVal"
        :state="getValidationState({ dirty, validated, valid, errors })"
        :aria-describedby="`input-${id}-live-feedback`"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :placeholder="placeholder"
        @keyup="onKeyUp"
        @blur="onBlur"
        :formatter="formatter"
        :readonly="readonly"
      ></b-form-input>
      <!-- @input="(val) => { if( textcase === 'upper') { inputVal = val.toUpperCase() } }" -->
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  // type:
  // text, password, email, number, url, tel, search, date, datetime, datetime-local, month, week, time, range, color
  props: {
    autocomplete: {
      type: String,
      default: "on",
    },
    readonly: {
      value: Boolean,
      default: false,
    },
    value: null,
    vid: String,
    name: String,
    label: String,
    placeholder: String,
    maxlength: {
      default: "50",
      type: String,
    },
    custom_type: {
      default: "text",
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
    textcase: {
      default: "normal",
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    onBlur(e) {
      // console.debug("input onBlur", e);
      this.$emit("blur", e);
    },
    onKeyUp(e) {
      this.$emit("keyup", e);
    },
    formatter(value) {
      let ret = value;
      switch (this.type) {
        case "text":
          if (this.textcase === "upper") {
            ret = value.toUpperCase();
          }
          break;
        case "tel":
          ret = value.replace(/\D/g, ""); // replace all non digits
          break;
        default:
          break;
      }
      return ret;
    },
  },
};
</script>
