import Repository from "@/repositories/Repository";

export default {
  create() {
    let resource = "/login";
    return Repository.get(`${resource}`);
  },
  okta(payload) {
    let resource = process.env.VUE_APP_OKTA_URL;
    return Repository.post(`${resource}`, payload);
  },
  csrf() {
    let resource = `${process.env.VUE_APP_CSRF_COOKIE_URL}`;
    console.debug(`VUE_APP_CSRF_COOKIE_URL: ${resource}`);
    return Repository.get(`${resource}`);
  },
  logout() {
    let resource = "/logout";
    return Repository.get(`${resource}`);
  },

  login(payload) {
    let resource = "/login";
    return Repository.post(`${resource}`, payload);
  },
};
