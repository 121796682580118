export default [
  {
    path: "/lisacomunica",
    name: "lisacomunica_template",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica" */ "@/views/profile/lisacomunica/Management.vue"
      ),
  },
  {
    path: "/lisacomunica-emails-create",
    name: "emails.create",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-emails" */ "@/views/profile/lisacomunica/emails/create.vue"
      ),
  },
  {
    path: "/lisacomunica-emails/:id/edit",
    name: "emails.edit",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-emails" */ "@/views/profile/lisacomunica/emails/edit.vue"
      ),
  },
  {
    path: "/lisacomunica-images-create",
    name: "images.create",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-images" */ "@/views/profile/lisacomunica/images/create.vue"
      ),
  },
  {
    path: "/lisacomunica-images/:id/edit",
    name: "images.edit",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-images" */ "@/views/profile/lisacomunica/images/edit.vue"
      ),
  },
  {
    path: "/lisacomunica-pdf-create",
    name: "pdf.create",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-pdf" */ "@/views/profile/lisacomunica/pdf/create.vue"
      ),
  },
  {
    path: "/lisacomunica-pdf/:id/edit",
    name: "pdf.edit",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-pdf" */ "@/views/profile/lisacomunica/pdf/edit.vue"
      ),
  },
  {
    path: "/lisacomunica-sms-create",
    name: "sms.create",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-sms" */ "@/views/profile/lisacomunica/sms/create.vue"
      ),
  },
  {
    path: "/lisacomunica-sms/:id/edit",
    name: "sms.edit",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-sms" */ "@/views/profile/lisacomunica/sms/edit.vue"
      ),
  },
  {
    path: "/lisacomunica-logs",
    name: "lisacomunica_log",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "lisacomunica-log" */ "@/views/profile/lisacomunica/logs/index.vue"
      ),
  },
];
