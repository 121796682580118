var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{attrs:{"mode":_vm.vmode,"vid":_vm.vid,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ dirty, validated, valid, errors }){return [_c('b-form-group',{class:{
      asterisk:
        Object.keys(_vm.rules).includes('required') ||
        (Object.keys(_vm.rules).includes('required_if') && !valid) ||
        (Object.keys(_vm.rules).includes('required_with') && !valid),
    },attrs:{"id":`input-group-${_vm.id}`,"label":_vm.label,"label-for":`input-${_vm.id}`}},[_c('b-form-input',{style:(_vm.type === 'currency' || _vm.custom_type === 'currency'
          ? { 'text-align': 'right' }
          : ''),attrs:{"id":`input-${_vm.id}`,"name":`input-${_vm.id}`,"type":_vm.type,"maxlength":_vm.maxlength,"state":_vm.getValidationState({ dirty, validated, valid, errors }),"aria-describedby":`input-${_vm.id}-live-feedback`,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"formatter":_vm.formatter,"readonly":_vm.readonly},on:{"keyup":_vm.onKeyUp,"blur":_vm.onBlur},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}}),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"id":`input-${_vm.id}-live-feedback`}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }