// init a object with keys equal the trasuryIndex code value, and value set to 0
const initTreasuryGross = (treasuryIndex) => {
  let initValue = 0;
  return treasuryIndex
    .map((a) => a.code)
    .reduce((acc, key) => ((acc[key] = initValue), acc), {});
};

const initSectorGross = (sectorIndex) => {
  let initValue = 0;
  return sectorIndex
    .map((a) => a.code)
    .reduce((acc, key) => ((acc[key] = initValue), acc), {});
};

// https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
const groupBy =
  (key, dataField = null, defaultUndefined = 0) =>
  (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      let value;
      if (key.split(".".length) === 1) {
        value = obj[key];
      } else {
        value = key.split(".").reduce((acc, part) => acc && acc[part], obj);
      }
      if (!value) {
        value = defaultUndefined; // set defaultUndefined
      }
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(
        dataField ? obj[dataField] : obj
      );
      return objectsByKeyValue;
    }, {});

const filterBy = (prop, attr) => (array, filterValue) => {
  if (prop) {
    // for each el in data array, filter el.'prop' by 'attr' value
    return array.map((el) => ({
      ...el,
      [prop]: el[prop].filter((ed) => ed[attr] == filterValue),
    }));
  } else {
    // attr belongs to root object, just filter
    return array.filter((el) => el[attr] == filterValue);
  }
};

// const throwMessage = (type, value) => {
//   let msg =
//     "Nella giornata selezionata sono presenti movimenti contabili senza forma di pagamento associata";
//   switch (type) {
//     case "treasury":
//       msg = `La cassa ${value} non è definita`;
//       break;
//     case "sector":
//       msg = `Il comparto della cassa ${value} non è definito`;
//       break;
//     default:
//       break;
//   }
//   throw msg;
// };

// Accepts the array and key
// const groupBy = (array, key) => {
//   // Return the end result
//   return array.reduce((result, currentValue) => {
//     // If an array already present for key, push it to the array. Else create an array and push the object
//     (result[currentValue[key]] = result[currentValue[key]] || []).push(
//       key.split(".".length) === 1
//         ? currentValue
//         : key.split(".").reduce((acc, part) => acc && acc[part], currentValue)
//     );
//     // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
//     return result;
//   }, {}); // empty object is the initial value for result object
// };

const filterTreasuryBySystemAndAbstract = (
  array,
  is_system,
  is_abstract,
  is_agency = null
) =>
  array.filter((e) => {
    if (Object.hasOwnProperty.call(e, "treasury")) {
      // if (!e.treasury) {
      //   throwMessage("treasury", e.treasury_id);
      // } else if (!e.treasury.sector) {
      //   throwMessage("sector", e.treasury_id);
      // }
      return (
        e.treasury?.sector?.is_system.value === is_system &&
        e.treasury?.sector?.is_abstract.value === is_abstract &&
        (is_agency != null
          ? e.treasury?.sector?.is_agency.value === is_agency
          : true)
      );
    } else {
      // if (!e.sector) {
      //   throwMessage("sector", e.treasury_id);
      // }
      return (
        e.sector?.is_system.value === is_system &&
        e.sector?.is_abstract.value === is_abstract &&
        (is_agency != null ? e.sector?.is_agency.value === is_agency : true)
      );
    }
  });

// get the codes from treasury index api array
const getTreasuryIndexCodesBySystemAndAbstract = (
  array,
  is_system,
  is_abstract,
  is_agency = null,
  field = "code"
) =>
  array
    // .filter(
    //   (e) =>
    //     e.sector.is_system.value === is_system &&
    //     e.sector.is_abstract.value === is_abstract &&
    //     (is_agency != null ? e.sector.is_agency.value === is_agency : true)
    // )
    .filter((e) => {
      if (Object.hasOwnProperty.call(e, "treasury")) {
        // if (!e.treasury) {
        //   throwMessage("treasury", e.treasury_id);
        // } else if (!e.treasury.sector) {
        //   throwMessage("sector", e.treasury_id);
        // }
        return (
          e.treasury?.sector?.is_system.value === is_system &&
          e.treasury?.sector?.is_abstract.value === is_abstract &&
          (is_agency != null
            ? e.treasury?.sector?.is_agency.value === is_agency
            : true)
        );
      } else {
        // if (!e.sector) {
        //   throwMessage("sector", e.treasury_id);
        // }
        return (
          e.sector?.is_system.value === is_system &&
          e.sector?.is_abstract.value === is_abstract &&
          (is_agency != null ? e.sector?.is_agency.value === is_agency : true)
        );
      }
    })
    .map((t) => t[field]);

// get the codes from sector index api array
const getSectorIndexCodesBySystemAndAbstractAndAgencyAndHidden = (
  array,
  is_system,
  is_abstract,
  is_agency = null,
  is_hidden = null,
  field = "code"
) =>
  array
    .filter(
      (e) =>
        e.is_system.value === is_system &&
        e.is_abstract.value === is_abstract &&
        (is_agency != null ? e.is_agency.value === is_agency : true) &&
        (is_hidden != null ? e.is_hidden.value === is_hidden : true)
    )
    .map((t) => t[field]);

// const getSectorTitleByCode = (array, code) =>
//   array.find((r) => r.code === code).title;

const sumAllProps = (obj) => {
  let sum = 0;
  for (const el in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, el)) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
};

const sumGross = (dataByInsurer, treasuryIndex) => {
  let data = {};
  let helper;
  for (const id in dataByInsurer) {
    helper = {};
    // init data to keep gross totals of all treasuries for the given insurer
    data[id] = {};
    let result = dataByInsurer[id].reduce(function (r, o) {
      let key = o.treasury?.code;
      if (!helper[key]) {
        helper[key] = Object.assign({}, o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].gross += o.gross;
      }
      return r;
    }, []);

    if (result.length) {
      // finally, for each combination, set the gross totals of the associated treasury codes
      let tmp;
      let code;

      let treasuryGross = initTreasuryGross(treasuryIndex);
      // first, prepare all data for the combinations...
      tmp = filterTreasuryBySystemAndAbstract(result, "Y", "N", "N");
      for (let i = 0; i < tmp.length; i++) {
        code = tmp[i].treasury?.code;
        treasuryGross[code] = parseFloat(tmp[i].gross.toFixed(2));
      }

      tmp = filterTreasuryBySystemAndAbstract(result, "N", "N", "N");
      for (let i = 0; i < tmp.length; i++) {
        code = tmp[i].treasury?.code;
        treasuryGross[code] = parseFloat(tmp[i].gross.toFixed(2));
      }

      tmp = filterTreasuryBySystemAndAbstract(result, "Y", "Y", "N");
      for (let i = 0; i < tmp.length; i++) {
        code = tmp[i].treasury?.code;
        treasuryGross[code] = parseFloat(tmp[i].gross.toFixed(2));
      }

      // then, set all prepared gross totals
      data[id] = treasuryGross;
    }
  }
  return data;
};

const sumGrossTotals = (arrayByInsurer, treasuryIndex) => {
  let grosses = initTreasuryGross(treasuryIndex);
  return arrayByInsurer.reduce(function (previousValue, currentValue) {
    for (const key in grosses) {
      grosses[key] = previousValue[key] + currentValue[key];
    }
    return grosses;
  }, grosses); // initial value
};

const sumGrossTotalsBySector = (arrayByInsurerBySector, sectorIndex) => {
  let grosses = initSectorGross(sectorIndex);
  return arrayByInsurerBySector.reduce(function (previousValue, currentValue) {
    for (const key in grosses) {
      // card 654
      if (key === "AC") {
        grosses[key] =
          Math.abs(previousValue[key]) + Math.abs(currentValue[key]);
      } else {
        grosses[key] = previousValue[key] + currentValue[key];
      }
    }
    return grosses;
  }, grosses); // initial value
};

// search array of objects for the property searchProp having value searchVal
// return matched.returnProp
const getByPropVal = (array, searchProp, searchVal, returnProp) => {
  let matched;
  if (searchProp.split(".".length) === 1) {
    matched = array.find((el) => el[searchProp] === searchVal);
  } else {
    matched = array.find(
      (el) =>
        searchProp.split(".").reduce((acc, part) => acc && acc[part], el) ===
        searchVal
    );
  }
  // return r ? r.gross : 0;
  if (!matched) {
    return "";
  } else {
    if (returnProp.split(".".length) === 1) {
      return matched[returnProp];
    } else {
      return returnProp
        .split(".")
        .reduce((acc, part) => acc && acc[part], matched);
    }
  }
};

export {
  getTreasuryIndexCodesBySystemAndAbstract as getTreasuryCodes,
  getSectorIndexCodesBySystemAndAbstractAndAgencyAndHidden as getSectorCodes,
  getByPropVal,
  sumAllProps,
  sumGross,
  sumGrossTotals,
  sumGrossTotalsBySector,
  groupBy,
  filterBy,
  filterTreasuryBySystemAndAbstract as getFilteredTreasuries,
};

// module.exports = {
//   getTreasuryCodes: getTreasuryIndexCodesBySystemAndAbstract,
//   getSectorCodes: getSectorIndexCodesBySystemAndAbstractAndHidden,
//   getByPropVal,
//   sumAllProps,
//   sumGross,
//   sumGrossTotals,
//   sumGrossTotalsBySector,
//   groupBy,
//   filterBy,
//   getFilteredTreasuries: filterTreasuryBySystemAndAbstract,
// };
