import store from "@/store";

export const canVerb = (resource, verb) => {
  const permissions = store.getters["auth/permissions"];
  return permissions.includes(`resources.${resource}.${verb}`);
};

export const canModule = (module) => {
  const permissions = store.getters["auth/permissions"];
  return permissions.includes(module);
};

export const isAdmin = (user = null, extraRoles = []) => {
  let adminRoles = ["admin-agenzia", "super-admin", "admin-assistenza"];
  if (user) {
    adminRoles = adminRoles.concat(extraRoles);
  }
  let roles = !user
    ? store.getters["auth/roles"]
    : user.roles.map((e) => e.name);
  let result = adminRoles.filter((role) => {
    return roles.indexOf(role) !== -1;
  });
  return result.length ? true : false;
};
