<template>
  <b-modal
    id="changePwd"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="lock"></b-icon>
        <span>{{ title }}</span>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-button @click="cancel()" size="sm" variant="outline-secondary"
          >CHIUDI</b-button
        >
        <b-button
          class="btn-outline-lisaweb"
          :disabled="
            !current_password ||
            !new_password ||
            !new_password_confirmation ||
            sent
          "
          @click="onConfirmNewPwd()"
          size="sm"
          variant="outline-success"
          >CONFERMA</b-button
        >
      </b-form-group>
    </template>
    <div>
      <b-form @submit.prevent="">
        <b-row>
          <div class="col-md-12">
            <base-input
              label="Password corrente"
              vid="current_password"
              id="current_password"
              name="current_password"
              type="password"
              v-model="current_password"
              :rules="{ required: true }"
              @input="onInputField"
            ></base-input>
          </div>
          <div class="col-md-12">
            <base-input
              label="Nuova Password"
              vid="new_password"
              id="new_password"
              name="new_password"
              type="password"
              v-model="new_password"
              :rules="{ required: true, min: 8 }"
              @input="onInputField"
            ></base-input>
            <small class="pwd-hint"
              >La nuova password deve includere almeno 1 carattere minuscolo, 1
              carattere maiuscolo, 1 numero, 1 carattere speciale e deve avere
              una lunghezza minima di 8 caratteri.</small
            >
          </div>
          <div class="col-md-12">
            <base-input
              label="Conferma Nuova Password"
              vid="new_password_confirmation"
              id="new_password_confirmation"
              name="new_password_confirmation"
              v-model="new_password_confirmation"
              type="password"
              :rules="{ required: true, min: 8 }"
              @input="onInputField"
            ></base-input>
          </div>
        </b-row>
        <b-row>
          <div class="col-md-12">
            <p class="success-message" v-show="success_msg && !dirty">
              {{ success_msg }}
            </p>
            <p class="error-message" v-show="error_msg && !dirty">
              {{ error_msg }}
            </p>
          </div>
        </b-row>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import client from "@/repositories/api";
export default {
  data() {
    return {
      current_password: null,
      new_password: null,
      new_password_confirmation: null,
      error_msg: null,
      success_msg: null,
      dirty: false,
    };
  },
  components: {
    BaseInput,
  },
  props: {
    title: {
      type: String,
      default: "Cambia password",
    },
  },
  computed: {},
  beforeMount() {},
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId !== "changePwd") return;
      this.current_password = null;
      this.new_password = null;
      this.new_password_confirmation = null;
      this.error_msg = null;
      this.success_msg = null;
      this.dirty = false;
      this.sent = false;
    });
  },
  methods: {
    onInputField() {
      this.dirty = true;
    },
    onConfirmNewPwd() {
      this.dirty = false;
      this.error_msg = null;
      this.success_msg = null;
      const url = `${process.env.VUE_APP_BASE_URL}/profile/change-password`;
      const bodyRequest = {
        current_password: this.current_password,
        new_password: this.new_password,
        new_password_confirmation: this.new_password_confirmation,
      };

      client
        .post(url, bodyRequest)
        .then((response) => {
          switch (response.status) {
            case 200: // OK
              this.success_msg = "Password modificata con successo.";
              this.sent = true;
              break;
            default:
              this.error_msg =
                "C'è stato un errore, ti preghiamo di riprovare più tardi. Se il problema persiste, ti invitiamo a contattare l'assistenza.";
          }
        })
        .catch((error) => {
          switch (error.status) {
            case 422: // Unprocessable Content
              this.error_msg =
                "Ti preghiamo di ricontrollare i dati inseriti, ti ricordiamo che la nuova password deve includere almeno 1 carattere minuscolo, 1 carattere maiuscolo, 1 numero, 1 carattere speciale e deve avere una lunghezza minima di 8 caratteri.";
              break;
            default:
              this.error_msg =
                "C'è stato un errore, ti preghiamo di riprovare più tardi. Se il problema persiste, ti invitiamo a contattare l'assistenza.";
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.pwd-hint) {
  display: block;
  margin: -1rem 0 1rem;
}
:deep(.error-message) {
  background: red;
  color: #fff;
  padding: 10px;
  margin: 0;
}
:deep(.success-message) {
  background: green;
  color: #fff;
  padding: 10px;
  margin: 0;
}
</style>
