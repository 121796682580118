import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import breadcrumbs from "@/store/breadcrumbs";
import cache from "@/store/cache";
// import config from "@/store/config";
// FUTURE
// import dictionaries from "@/store/dictionaries";
import filters from "@/store/filters";
import pagination from "@/store/pagination";
import queue from "@/store/queue";
import quicksearch from "@/store/quicksearch";
import sidebar from "@/store/sidebar";
import sorts from "@/store/sorts";
import tabs from "@/store/tabs";
import lisacomunica_automodal from "@/store/lisacomunica_automodal";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    breadcrumbs,
    cache,
    // config,
    // FUTURE
    // dictionaries,
    filters,
    pagination,
    queue,
    quicksearch,
    sidebar,
    sorts,
    tabs,
    lisacomunica_automodal,
    // other modules here ...
  },
});
