<template>
  <div>
    <b-modal
      id="searchResults"
      size="lg"
      ok-only
      ok-title="Chiudi"
      ok-variant="lisaweb"
      header-bg-variant="lisaweb"
      @ok="$emit('close')"
    >
      <!-- no-close-on-esc
      no-close-on-backdrop -->
      <template #modal-header="{ cancel }">
        <div slot="modal-title" class="modal-title">
          <b-icon icon="search"></b-icon>
          <span>Risultati Ricerca Rapida</span>
        </div>
        <button
          type="button"
          class="close"
          title="Chiudi"
          data-dismiss="modal"
          @click="
            $emit('close');
            cancel();
          "
        >
          &times;
        </button>
      </template>

      <b-row>
        <div
          class="col-md-12"
          v-if="Object.values(results).some((e) => e.length)"
        >
          <p>
            La ricerca di <b>{{ term }}</b> ha prodotto i seguenti risultati
          </p>
          <div
            v-for="(array, entity, index1) in results"
            :key="'l1-' + index1"
            class="mt-3"
          >
            <b-card
              v-if="array.length"
              :header="getEntityLabel(entity)"
              header-tag="header"
              class="mb-2"
            >
              <b-card-text>
                <ul>
                  <span v-for="(obj, index2) in array" :key="'l2-' + index2">
                    <li
                      v-for="(result, index3) in obj.found"
                      :key="'l3-' + index3"
                    >
                      <b-link
                        :href="entity"
                        @click.prevent="
                          openEntityModal(
                            result.id,
                            entity,
                            getFoundLabel(result, entity)
                          )
                        "
                      >
                        <span v-html="getFoundLabel(result, entity)"></span>
                      </b-link>
                      <span>
                        tag: {{ obj.term }}
                        <!-- affinity: {{ obj.affinity }},
                        occorrenze: {{ obj.occurrency }} -->
                      </span>
                    </li>
                  </span>
                </ul>
              </b-card-text>
            </b-card>
          </div>
          <!-- <div
            v-for="(value, index1) in results"
            :key="'l1-' + index1"
            class="mt-3"
          >
            <div v-for="(found, index2) in value.found" :key="'l2-' + index2">
              <b-card
                v-if="found[Object.keys(found)[0]].length"
                :header="getEntityLabel(Object.keys(found)[0]) +
                  ` (occorrenze: ${value.occurrency}, affinità: ${value.affinity})`"
                header-tag="header"
                class="mb-2"
              >
                <b-card-text>
                  <ul>
                    <li
                      v-for="(result, index3) in found[Object.keys(found)[0]]"
                      :key="'l3-' + index3"
                    >
                      <b-link
                        :href="Object.keys(found)[0]"
                        @click.prevent="
                          openModal(
                            result.id,
                            Object.keys(found)[0],
                            getFoundLabel(result, Object.keys(found)[0])
                          )
                        "
                      >
                        {{ getFoundLabel(result, Object.keys(found)[0]) }}
                      </b-link>
                      <div>
                        term: {{ value.term }}, affinity: {{ value.affinity }},
                        occorrenze: {{ value.occurrency }}
                      </div>
                    </li>
                  </ul>
                </b-card-text>
              </b-card>
            </div>
          </div> -->
        </div>
        <div class="col-md-12" v-else>Nessun risultato.</div>
      </b-row>
    </b-modal>
    <!-- :title="detailTitle" -->
    <b-modal
      :id="searchmodalName"
      size="xl"
      ok-only
      ok-title="Chiudi"
      ok-variant="lisaweb"
      header-bg-variant="lisaweb"
      no-close-on-backdrop
      @hidden="onModalHidden"
    >
      <template #modal-header="{ cancel }">
        <div slot="modal-title" class="modal-title">
          <b-icon icon="search"></b-icon>
          <span>{{ detailTitle }} </span>
        </div>
        <button
          type="button"
          class="close"
          title="Chiudi"
          data-dismiss="modal"
          @click="cancel()"
        >
          &times;
        </button>
      </template>
      <div>
        <b-tabs content-class="pt-1" lazy v-model="tabIndex">
          <b-tab
            v-for="(tab, index) in tabs[entity]"
            :active="index === tabIndex"
            :key="index"
            :title="tab.text"
            @click="setTab(tab, index, entity)"
            title-item-class="lisaweb"
          >
            <keep-alive>
              <component
                v-if="selected === tab.name"
                :is="selected"
                :resourceId="modalResourceId"
              ></component>
            </keep-alive>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>
<script>
import template from "@/components/tables/template";

export default {
  name: "searchResults",
  extends: template,
  data() {
    return {
      modalResourceId: null,
      searchmodalName: "searchResultDetails",
      entity: null,
      selected: null,
      tabIndex: 0,
      tabs: {
        registry: [
          { name: "registryGeneral", text: "Generale" },
          { name: "registryCorrespondences", text: "Indirizzi" },
          { name: "registryInsurancePolicy", text: "Polizze" },
          { name: "registryInsuranceAncillary", text: "Titoli" },
          { name: "registryTakings", text: "Incassi" },
          { name: "registryClaim", text: "Sinistri" },
          { name: "registryAppointments", text: "Appuntamenti" },
          { name: "registryNotes", text: "Note" },
          { name: "registryTodo", text: "Attività" },
          { name: "registryGroup", text: "Gruppi" },
          { name: "registryDocuments", text: "Doc" },
          { name: "registryRelations", text: "Relazioni" },
          { name: "registryPrints", text: "Stampe" },
        ],
        insurance_policy: [
          { name: "insurance_policyGeneral", text: "Generale" },
          { name: "insurance_policyTechnicalData", text: "Dati tec." },
          { name: "insurance_policyRegistry", text: "Contraente" },
          { name: "insurance_policyGroup", text: "Gruppo" },
          { name: "insurance_policyTitles", text: "Titoli" },
          { name: "insurance_policyAccounting", text: "Incassi" },
          { name: "insurance_policyAccident", text: "Sinistri" },
          { name: "insurance_policyAppointments", text: "Appuntamenti" },
          { name: "insurance_policyNotes", text: "Note" },
          { name: "insurance_policyTodo", text: "Attività" },
          { name: "insurance_policyDocuments", text: "Doc" },
        ],
        claim: [
          { name: "claimGeneral", text: "Generale" },
          { name: "claimRegistry", text: "Anagrafica" },
          { name: "claimPolicy", text: "Polizza" },
          { name: "claimSurveyor", text: "Perito" },
          { name: "claimCounterparties", text: "Controparti" },
          { name: "claimDriver", text: "Conducente" },
          { name: "claimGarage", text: "Officina" },
          { name: "claimAppointments", text: "Appuntamenti" },
          { name: "claimNotes", text: "Note" },
          { name: "claimTodo", text: "Attività" },
          { name: "claimDocuments", text: "Doc" },
        ],
        agency_contact: [
          { name: "agency_contactGeneral", text: "Generale" },
          { name: "agency_contactAppointments", text: "Appuntamenti" },
          { name: "agency_contactNotes", text: "Note" },
          { name: "agency_contactTodo", text: "Attività" },
          { name: "agency_contactDocument", text: "Documenti" },
        ],
      },
    };
  },
  components: {
    registryGeneral: () =>
      import("@/components/wallet/registries/details/General"),
    registryCorrespondences: () =>
      import("@/components/wallet/registries/details/Correspondences"),
    registryInsurancePolicy: () =>
      import("@/components/wallet/registries/details/InsurancePolicy"),
    registryInsuranceAncillary: () =>
      import("@/components/wallet/registries/details/InsuranceAncillary"),
    registryTakings: () =>
      import("@/components/wallet/registries/details/Takings"),
    registryClaim: () => import("@/components/wallet/registries/details/Claim"),
    registryAppointments: () =>
      import("@/components/wallet/registries/details/Appointments"),
    registryTodo: () => import("@/components/wallet/registries/details/Todo"),
    registryNotes: () => import("@/components/wallet/registries/details/Notes"),
    registryGroup: () => import("@/components/wallet/registries/details/Group"),
    registryDocuments: () =>
      import("@/components/wallet/registries/details/Documents"),
    registryRelations: () =>
      import("@/components/wallet/registries/details/Relations"),
    registryPrints: () =>
      import("@/components/wallet/registries/details/Prints"),

    insurance_policyGeneral: () =>
      import("@/components/wallet/policies/details/General"),
    insurance_policyTechnicalData: () =>
      import("@/components/wallet/policies/details/TechnicalData"),
    insurance_policyRegistry: () =>
      import("@/components/wallet/policies/details/Registry"),
    insurance_policyGroup: () =>
      import("@/components/wallet/policies/details/Group"),
    insurance_policyTitles: () =>
      import("@/components/wallet/policies/details/Titles"),
    insurance_policyAccident: () =>
      import("@/components/wallet/policies/details/Claims"),
    insurance_policyNotes: () =>
      import("@/components/wallet/policies/details/Notes"),
    insurance_policyTodo: () =>
      import("@/components/wallet/policies/details/Todo"),
    insurance_policyAccounting: () =>
      import("@/components/wallet/policies/details/Accounting"),
    insurance_policyAppointments: () =>
      import("@/components/wallet/policies/details/Appointments"),
    insurance_policyDocuments: () =>
      import("@/components/wallet/policies/details/Documents"),

    agency_contactTodo: () =>
      import("@/components/wallet/agency-contacts/details/Todo"),
    agency_contactGeneral: () =>
      import("@/components/wallet/agency-contacts/details/General"),
    agency_contactDocument: () =>
      import("@/components/wallet/agency-contacts/details/Documents"),
    agency_contactNotes: () =>
      import("@/components/wallet/agency-contacts/details/Notes"),
    agency_contactAppointments: () =>
      import("@/components/wallet/agency-contacts/details/Appointments"),

    claimGeneral: () => import("@/components/wallet/claims/details/General"),
    claimRegistry: () => import("@/components/wallet/claims/details/Registry"),
    claimPolicy: () => import("@/components/wallet/claims/details/Policy"),
    claimSurveyor: () => import("@/components/wallet/claims/details/Surveyor"),
    claimCounterparties: () =>
      import("@/components/wallet/claims/details/Counterparties"),
    claimGarage: () => import("@/components/wallet/claims/details/Garage"),
    claimNotes: () => import("@/components/wallet/claims/details/Notes"),
    claimTodo: () => import("@/components/wallet/claims/details/Todo"),
    claimDriver: () => import("@/components/wallet/claims/details/Driver"),
    claimAppointments: () =>
      import("@/components/wallet/claims/details/Appointments"),
    claimDocuments: () =>
      import("@/components/wallet/claims/details/Documents"),
  },
  props: {
    results: {
      type: Object,
      default() {
        return {};
      },
    },
    term: String,
  },
  methods: {
    openEntityModal(id, entity, title) {
      this.modalResourceId = id;
      this.entity = entity;
      this.selected = `${entity}General`;
      // this.modalTitle = `${this.getEntityLabel(entity)} :: ${title}`;
      this.detailTitle = `${this.getEntityLabel(entity)} :: ${title}`;

      this.$bvModal.show(this.searchmodalName);
    },
    onModalHidden() {
      // override template's deinition
      this.entity = null;
      this.selected = null;
      this.tabIndex = 0;
    },
    getEntityLabel(entity) {
      let ret = "";
      switch (entity) {
        case "registry":
          ret = "Anagrafiche";
          break;
        case "agency_contact":
          ret = "Contatti";
          break;
        case "claim":
          ret = "Sinistri";
          break;
        case "insurance_policy":
          ret = "Polizze";
          break;
      }
      return ret;
    },
    getFoundLabel(result, entity) {
      let ret;
      switch (entity) {
        case "registry":
          ret = `${result.title} :: ${this.toInfoData(
            result,
            "address",
            null,
            " "
          )}`;
          // ret = `${result.title} :: ${
          //   result.correspondences.find(
          //     (e) => e.status_correspondence.value == 0
          //   ).geo_location.CAP
          // } :: ${
          //   result.correspondences.find(
          //     (e) => e.status_correspondence.value == 0
          //   ).geo_location.COUNCIL
          // } (${
          //   result.correspondences.find(
          //     (e) => e.status_correspondence.value == 0
          //   ).geo_location.PRVCODE
          // })`;
          // ret = `${result.title} :: ${this.toRelationString(
          //   "correspondences",
          //   result.correspondences,
          //   ["ZIPC"]
          // )} ${this.toRelationString(
          //   "correspondences",
          //   result.correspondences,
          //   ["CITY"]
          // )}`;
          /*  (${this.toRelationString(
            "correspondences",
            result.correspondences,
            ["VIRTUAL_CITY_PROVINCE"]
          ).toUpperCase()}) 
          */
          break;
        case "agency_contact":
          ret = `${result.title}`;
          break;
        case "claim":
          ret = `${result.title} :: ${result.number} :: ${result.insurance_policy.title}`;
          break;
        case "insurance_policy":
          ret = `${result.title} :: ${result.number} :: ${result.registry.title}`;
          break;
      }
      return ret;
    },
    // openResult(id, entity) {
    //   // switch (entity) {
    //   //   case "registry":
    //   //     break;
    //   //   case "agency_contact":
    //   //     break;
    //   //   case "claim":
    //   //     break;
    //   //   case "insurance_policy":
    //   //     break;
    //   // }
    //   // this.openModal(id, entity);
    // },
  },
  computed: {
    selTab: {
      set(value) {
        this.selected = value;
      },
      get() {
        return this.selected;
      },
    },
  },
  // mounted() {
  //   this.$refs['myRelation'].firstChild.addEventListener('click', function(event) {
  //     event.preventDefault();
  //     console.log('clicked: ', event.target);
  //   })
  // },
  beforeMount() {},
};
</script>
<style lang="scss" scoped></style>
