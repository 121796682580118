import { mapGetters, mapActions } from "vuex";
import { prepareFilterQueryString } from "@/utils/forms";

export default {
  data: () => ({}),
  methods: {
    prepareFilterQueryString,
    loadFilter(name) {
      let criteria = this.loadFilterByName()(name);
      if (!criteria) return "";
      let ret = this.prepareFilterQueryString(criteria);
      return ret;
    },
    loadSort(name) {
      let criteria = { sortBy: this.sortBy, sortDesc: this.sortDesc };
      let str = "";
      if (!this.sortBy) {
        // load and set sort criteria
        criteria = { ...this.loadSortByName()(name) };
        this.sortBy = criteria.sortBy;
        this.sortDesc = criteria.sortDesc;
      } else {
        // save it anyway
        this.saveSortByName({ name, criteria });
      }
      // &sort[byRegistryGroup]=code&dir[byRegistryGroup]=asc
      if (criteria.sortBy) {
        let spl = criteria.sortBy.split(".");
        if (spl.length === 2) {
          str = `sort[${spl[0]}]=${spl[1]}&dir[${spl[0]}]=${
            criteria.sortDesc ? "desc" : "asc"
          }`;
        } else {
          str = `sort[byAttribute]=${spl[0]}&dir[byAttribute]=${
            criteria.sortDesc ? "desc" : "asc"
          }`;
        }
      }
      return str;
    },
    loadPagination(name) {
      let criteria;
      let str = "";
      console.debug(`loadPagination: ${name} perPage="${this.perPage}"`);
      if (!this.perPage) {
        // if (!this.perPage) {
        if (this.perPage === false || this.perPage === undefined) {
          // init with default value which is set on the agency settings, inside the db
          // RPP = Record per pagina
          this.perPage = this.setting()("attribute_RPP_value");
        }
        criteria = { currentPage: this.currentPage, perPage: this.perPage };
        // load and set page criteria
        const pagination_criteria = { ...this.loadPaginationByName()(name) };
        if (pagination_criteria.currentPage && pagination_criteria.perPage) {
          criteria = pagination_criteria;
          this.currentPage = criteria.currentPage;
          this.perPage = criteria.perPage;
          // console.debug(
          //   `loadPagination: set currentPage=${this.currentPage}, perPage=${this.perPage}`
          // );
        }
        // console.debug(
        //   `loadPagination: GET criteria=${JSON.stringify(criteria)}`
        // );
      } else {
        criteria = { currentPage: this.currentPage, perPage: this.perPage };
        // update pagination
        // console.debug(
        //   `loadPagination: SET criteria=${JSON.stringify(criteria)}`
        // );
        this.savePaginationByName({ name, criteria });
      }
      str = `page=${criteria.currentPage}${
        criteria.perPage ? "&perPage=" + criteria.perPage : ""
      }`;
      return str;
    },
    sortingChanged(ctx) {
      // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
      // ctx.sortDesc ==> true if sorting descending, false otherwise
      // console.debug(`ctx.sortBy: ${ctx.sortBy}, ctx.sortDesc: ${ctx.sortDesc}`);
      // console.debug(`sortBy: ${this.sortBy}, sortDesc: ${this.sortDesc}`);
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      // let filter = this.keepFilter();
      this.keepFilter();
      this.fetch().then(() => {
        // per evitare AUTOFETCH:
        // if (filter) {
        //   let name = this.filterName;
        //   this.removeFilterByName(name);
        // }
      });
    },
    keepFilter() {
      // REVIEW: access through $parent... perhaps could go another way
      let filter =
        this.$parent.$data.filter ||
        this.$parent.$parent.$data.filter ||
        this.$parent.$parent.$parent.$data.filter;
      if (filter) {
        let name = this.filterName;
        let criteria = filter[name] || filter;
        this.saveFilterByName({ name, criteria });
      }
      return filter;
    },
    ...mapGetters("filters", {
      loadFilterByName: "loadByName",
    }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    // ...mapGetters("auth", ["customAttributes"]),
    ...mapGetters("auth", ["setting"]),
    ...mapActions("sorts", { saveSortByName: "saveByName" }),
    ...mapGetters("sorts", { loadSortByName: "loadByName" }),
    ...mapActions("pagination", {
      savePaginationByName: "saveByName",
      removePaginationByName: "removeByName",
    }),
    ...mapGetters("pagination", { loadPaginationByName: "loadByName" }),
  },
  computed: {
    // curPage: {
    //   set(value) {
    //     this.currentPage = value;
    //   },
    //   get() {
    //     return this.currentPage;
    //   },
    // },
  },
  mounted() {},
  beforeMount() {},
};
