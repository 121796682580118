import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/users";
export default {
  create() {
    // get the create form
    // let resource = "/users/create";
    return Repository.get(`${resource}/create`);
  },
  edit(id) {
    // get the edit form
    // let resource = `/users/${id}/edit`;
    return Repository.get(`${resource}/${id}/edit`);
  },
  validate(field, payload) {
    // let resource = `/users/validation/${field}`;
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    // delete the item
    return Repository.delete(`${resource}/${id}`);
  },
  pivot_store(id, relation, payload) {
    return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
  },
  pivot_destroy(id, relation, payload) {
    return Repository.delete(`${resource}/${id}/pivot/${relation}`, {
      data: payload,
    });
  },
  //PERMISSIONS
  show_permissions(user_id) {
    // get user permissions
    return Repository.get(`${resource}/${user_id}/permissions`);
  },
  destroy_permission(user_id, permission_id) {
    // delete user permission
    return Repository.delete(
      `${resource}/${user_id}/permissions/${permission_id}`
    );
  },
  store_permission(user_id, payload) {
    // save user permissions
    return Repository.post(`${resource}/${user_id}/permissions`, payload);
  },
};
