/**
 * return a new object by removing all keys except those allowed
 * @param {Object} obj original object
 * @param {Array} allowedKeys keys to be kept
 * @returns {Object}
 */
export const reduceByKeys = (obj, allowedKeys) => {
  let reduced = Object.keys(obj).reduce(function (newObj, key) {
    if (allowedKeys.indexOf(key) !== -1) {
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
  return reduced;
};

/**
 * return a new object by removing keys
 * @param {Object} obj original object
 * @param {Array} removableKeys keys to be kept
 * @returns {Object}
 */
export const removeKeys = (obj, removableKeys) => {
  let reduced = Object.keys(obj).reduce(function (newObj, key) {
    if (removableKeys.indexOf(key) === -1 && obj[key].type !== "hidden") {
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
  return reduced;
};

/**
 * return the keys of fields object, removing removableKeys
 * @param {Object} fields
 * @param {Array} removableKeys
 * @returns {Array}
 */
export const getFormFieldsKeys = (
  fields,
  removableKeys = ["submit", "_namespace"]
) => {
  let keys = [];
  //  let removableKeys = ["submit", "_namespace"];
  let flds = Object.assign({}, fields);
  flds = removeKeys(flds, removableKeys);
  keys = Object.keys(flds);
  return keys;
};

/**
 * Known backend combinations so far:
 *
 * "type": "text | textarea | select |  radio | checkbox",
 * "class": "",
 *
 * "type": "text",
 * "class": "datepicker | timepicker",
 *
 *  NOTE: single/multi choice select/checkbox combination is still UNKNOWN!!!
 *
 * @param {*} beFormItem
 * @returns String
 */
export const getInputType = (beFormItem) => {
  if (!beFormItem) {
    console.info("NO beFormItem !!!", beFormItem);
    return null;
  }
  if (!beFormItem.type) {
    console.info("NO beFormItem.type !!!", beFormItem);
  }
  let beType = beFormItem.type;
  let beClass = beFormItem.class;
  let inputType = beType;
  if (!beType && !beClass) {
    if (Array.isArray(beFormItem)) {
      inputType = "array";
    }
    return inputType;
  }
  switch (beType) {
    case "text":
      switch (beClass) {
        case "datepicker":
        case "timepicker":
          inputType = beClass;
          break;
        case "number":
          inputType = beClass; // TODO: check this!!!
          break;
        case "numeric":
          inputType = "currency";
          break;
        case "":
        default:
          inputType = beType;
          break;
      }
      break;
    case "float":
      switch (beClass) {
        case "number":
          inputType = "decimal";
          break;
        default: // TODO: check this!!!
          inputType = "text";
          break;
      }
      break;
    default:
      inputType = beType;
      break;
  }
  return inputType;
};

/**
 * initialize values of component's form for the given form keys
 * @param {Array} keys
 * @param {Object} beForm the back end form
 * @returns {Object}
 */
export const setFormValues = (keys, beForm) => {
  let obj = {};
  for (let key of keys) {
    // let type = beForm[key].class || beForm[key].type;
    let type = getInputType(beForm[key]);
    let value = ""; // = beForm[key].value !== undefined ? beForm[key].value : "";

    switch (type) {
      case "hidden":
        break;
      case "array":
        // è un array, lo copio così com'è
        value = beForm[key];
        break;
      // case "text":
      // case "textarea":
      // case "password":
      //   break;
      // case "select":
      //   break;
      // case "checkbox":
      //   break;
      // case "datepicker":
      //   // value = value
      //   //   .split("-")
      //   //   .reverse()
      //   //   .join("/");
      //   break;
      // case "timepicker":
      //   break;
      default:
        value = beForm[key].value !== undefined ? beForm[key].value : "";
        break;
    }
    obj[key] = value;
  }
  return obj;
};
/**
 * match regex to get 'resource' item from url
 * url pattern: /api/resource/validation/field
 * @param {String} url
 * @param {String} field
 * @returns {String}
 */
export const getResource = (url, field) => {
  if (url && field) {
    // PROBLEM: lookahead and lookbehind support missing (Safari and Firefox)
    // let exclude = ["api", "validation", field];
    // const re = /(?<!\?.+)(?<=\/)[\w-]+(?=[/\r\n?]|$)/g;
    // const m = url.match(re);
    // const r = m.filter((i) => {
    //   return !exclude.includes(i);
    // });
    // if (r.length === 1) {
    //   return r[0];
    // }
    const re = /\/api\/([a-zA-Z0-9_-]+)\/validation\/([a-zA-Z0-9_-]+)/;
    const m = url.match(re);
    if (m) {
      const res = re.exec(url);
      // controllo extra (ridondante?)
      if (res[2] === field) {
        return res[1];
      }
    }
  }
  console.info(`getResource: not found in ${url}`);
  return "";
};

export const prepareFilterQueryString = (obj) => {
  // obj = {byAttribute: {id: 1, producer: ["GAA","SER","GAR"],NAME: 'Mario', ISPL: {CAP: 23807} },
  // byRegistryGroup: {code: 'CWJ'}, byCalendar: '2021-08-01','2021-08-31'};
  obj = removeEmpty(obj);

  let retByCurrency = {};
  // let retByNet = {};
  let retByInsuranceStatus = {};
  let retBySettled = {};
  let retByReferenceName = {};
  let retByManual = {};
  let retbyRelatedTasks = {};
  let retByCalVar = {};
  let retByExp = {}; // byStocastico
  let retByCus = [];
  let retByBrkEnh = [];
  let retByRel = [];
  let sinceTheEpoch = "1900-01-01";

  // custom filter byRelations
  if (Object.prototype.hasOwnProperty.call(obj, "byRelations")) {
    // byRelations: ['byRegistry', 'byClaim']
    // => byRegistry&byClaim
    let byRelations = obj["byRelations"];
    if (Array.isArray(byRelations)) {
      retByRel.push(...byRelations);
    } else {
      retByRel.push(byRelations);
    }
    delete obj.byRelations;
  }

  // custom filter byStocastico
  // NOTE: era byExpiration ma wallace non ha voluto cambiare il nome,
  // nonostante la presenza della variante byExpiration di byCalendar
  if (Object.prototype.hasOwnProperty.call(obj, "byStocastico")) {
    let byStocastico = obj["byStocastico"];
    if (
      Object.prototype.hasOwnProperty.call(byStocastico, "date") &&
      byStocastico.date &&
      Object.prototype.hasOwnProperty.call(byStocastico, "status") &&
      byStocastico.status.length
    ) {
      retByExp = byStocastico;
    }
    delete obj.byStocastico;
  }

  // custom filter byReferenceName
  if (Object.prototype.hasOwnProperty.call(obj, "byReferenceName")) {
    retByReferenceName = obj["byReferenceName"];
    delete obj.byReferenceName;
  }

  // custom filter byCurrency
  if (Object.prototype.hasOwnProperty.call(obj, "byCurrency")) {
    retByCurrency = obj["byCurrency"];
    delete obj.byCurrency;
  }

  // custom filter byNet
  // if (Object.prototype.hasOwnProperty.call(obj, "byNet")) {
  //   retByNet = obj["byNet"];
  //   delete obj.byNet;
  // }

  // custom filter byManual
  if (Object.prototype.hasOwnProperty.call(obj, "byManual")) {
    retByManual = obj["byManual"];
    delete obj.byManual;
  }

  // custom filter retbyRelatedTasks
  if (Object.prototype.hasOwnProperty.call(obj, "byRelatedTasks")) {
    retbyRelatedTasks = obj["byRelatedTasks"];
    delete obj.byRelatedTasks;
  }

  // custom filter byInsuranceStatus
  if (Object.prototype.hasOwnProperty.call(obj, "byInsuranceStatus")) {
    retByInsuranceStatus = obj["byInsuranceStatus"];
    delete obj.byInsuranceStatus;
  }

  // custom filter bySettled
  if (Object.prototype.hasOwnProperty.call(obj, "bySettled")) {
    retBySettled = obj["bySettled"];
    delete obj.bySettled;
  }

  // byCalendar and variants
  const byCalendarVariants = [
    "byCalendar",
    "byCreatedAt",
    "byCashTime",
    "byExpiration",
    "byIssue",
    "byValidity",
    "byCoverage",
    "bySuspension",
    "byCancellation",
    "byEffectiveness",
    "byFrom",
    "byTo",
  ];
  byCalendarVariants.forEach((variant) => {
    if (Object.prototype.hasOwnProperty.call(obj, variant)) {
      let byVariant = obj[variant];
      retByCalVar[variant] = [];
      if (
        Object.prototype.hasOwnProperty.call(byVariant, "from") &&
        byVariant.from
      ) {
        retByCalVar[variant].push(byVariant.from);
      }
      if (
        Object.prototype.hasOwnProperty.call(byVariant, "to") &&
        byVariant.to
      ) {
        if (retByCalVar[variant].length) {
          retByCalVar[variant].push(byVariant.to);
        } else {
          retByCalVar[variant].push(`${sinceTheEpoch},${byVariant.to}`);
        }
      }
      delete obj[variant];
    }
  });

  // custom filter byCustom
  // NOTA: non serve più perché il nuovo formato del QS è:
  // byReduced[after | before | at]=yyyy-mm-dd
  // perciò basta impostare this.filter nel modo consueto...
  if (Object.prototype.hasOwnProperty.call(obj, "byCustom")) {
    // NOTE: this is the format:
    // {
    //   field: {
    //     value: val,
    //     likewise: 0|1|2|4 (0: none, 1:prefix, 2: suffix: 4: prefix and suffix)
    //     filter: 'bySomething'
    //   }
    // }
    // => val ? bySomething[field]=
    // => byDeferredStatus=still_deferred
    let byCustom = obj["byCustom"];
    for (const key in byCustom) {
      // if byEnhanced[key] is null, continue
      if (!byCustom[key]) {
        continue;
      }
      let value;
      // if byCustom[key] is an object -> byDeferredStatus=SCOPE, DATE
      if (typeof byCustom[key] === "object") {
        switch (byCustom[key].likewise) {
          case 0:
            value = byCustom[key].value;
            break;
          case 1:
            value = `%${byCustom[key].value}`;
            break;
          case 2:
            value = `${byCustom[key].value}%`;
            break;
          case 4:
            value = `%${byCustom[key].value}%`;
            break;

          default:
            value = byCustom[key].value;
            break;
        }
        if (byCustom[key].value) {
          // there is a value
          retByCus.push(`${byCustom[key].filter}[${key}]=${value}`);
        }
      }
    }
    delete obj.byCustom;
  }

  /*
  // custom filter byEnhanced 
  // NOTA: non serve più perché il nuovo formato del QS è:
  // byReduced[after | before | at]=yyyy-mm-dd
  // perciò basta impostare this.filter nel modo consueto...
  if (Object.prototype.hasOwnProperty.call(obj, "byEnhanced")) {
    // NOTE: this is the format:
    // {
    //   reduced_after: {
    //     value: 2021-10-15,
    //     filter: 'byDeferredStatus'
    //   }
    // }
    // => byDeferredStatus=reduced_after, 2021-10-15
    // OR
    // {
    //   value: "still_deferred"
    // }
    // => byDeferredStatus=still_deferred
    let byEnhanced = obj["byEnhanced"];
    for (const key in byEnhanced) {
      // if byEnhanced[key] is null, continue
      if (!byEnhanced[key]) {
        continue;
      }
      // if byEnhanced[key] is an object -> byDeferredStatus=SCOPE, DATE
      if (typeof byEnhanced[key] === "object") {
        if (byEnhanced[key].value) {
          // there is a value
          retByEnh.push(
            `${byEnhanced[key].filter}=${key},${byEnhanced[key].value}`
          );
        }
      } else {
        // key is "value" -> byDeferredStatus=VALUE
        if (key === "value") {
          retByEnh.push(`byDeferredStatus=${byEnhanced[key]}`);
        }
      }
    }
    delete obj.byEnhanced;
  }
  */
  // custom filter byBrokerEnhanced
  if (Object.prototype.hasOwnProperty.call(obj, "byBrokerEnhanced")) {
    // NOTE: this is the format:
    // {
    //   salesmen: [],
    //   cooperators: []
    // }
    // => byBroker[id]=1,2,...n
    let byBrokerEnhanced = obj["byBrokerEnhanced"];
    for (const key in byBrokerEnhanced) {
      // if byEnhanced[key] is empty, continue
      if (!byBrokerEnhanced[key]) {
        continue;
      }
      if (typeof byBrokerEnhanced[key] === "object") {
        if (byBrokerEnhanced[key].length) {
          retByBrkEnh.push(...byBrokerEnhanced[key]);
        }
      } else {
        retByBrkEnh.push(byBrokerEnhanced[key]);
      }
    }
    delete obj.byBrokerEnhanced;
  }

  let dotObj = changeObjectToDotNotationFormat(obj);

  let result = Object.keys(dotObj).map((key) => {
    let s = key.split(".");
    if (s.length > 1) {
      let k0 = s[0];
      let k1 = s.slice(1).join(".");
      switch (k0) {
        // case "byCalendar":
        //   return;
        default:
          return `${k0}[${k1}]=${dotObj[key]}`;
      }
    } else {
      switch (key) {
        // case "byCalendar":
        //   //byCalendar=yyyy-mm-dd(,yyyy-mm-dd)?
        //   // return `${key}=${dotObj[key].from ? dotObj[key].from : null},${
        //   //   dotObj[key].to ? dotObj[key].to : null
        //   // }`;
        //   return `${key}=${dotObj[key]}`;
        // return;
        case "byView":
        case "byDeferred":
          return `${key}=${dotObj[key]}`;
        default:
          return `${key}=${dotObj[key]}`;
      }
    }
  });

  if (retByRel.length) {
    // result = [...result, ...retByRel];
    result.push(...retByRel);
  }

  // if (retByCal.length) {
  //   result.push(`byCalendar=${retByCal.join(",")}`);
  // }
  if (Object.keys(retByCalVar).length) {
    for (const [key, value] of Object.entries(retByCalVar)) {
      if (value.length) {
        result.push(`${key}=${value.join(",")}`);
      }
    }
  }

  if (Object.keys(retByExp).length) {
    result.push(`byExpiration[${retByExp.date}]=${retByExp.status.join(",")}`);
  }

  if (Object.keys(retByManual).length) {
    result.push(`byManual`);
  }

  if (Object.keys(retbyRelatedTasks).length) {
    result.push(`byRelatedTasks`);
  }

  if (Object.keys(retByInsuranceStatus).length) {
    result.push(`byInsuranceStatus=${retByInsuranceStatus.value}`);
  }

  if (Object.keys(retBySettled).length) {
    result.push(`bySettled=${retBySettled}`);
  }

  if (Object.keys(retByReferenceName).length) {
    result.push(`byReferenceName=${retByReferenceName.value}`);
  }

  if (Object.keys(retByCurrency).length) {
    if (Object.hasOwnProperty.call(retByCurrency, "not")) {
      result.push(`byCurrency=${retByCurrency.not}`);
    } else {
      const currency_from = retByCurrency.from ? retByCurrency.from : 0;
      const currency_to = retByCurrency.to ? `,${retByCurrency.to}` : "";
      result.push(`byCurrency=${currency_from}${currency_to}`);
    }
  }

  // if (Object.keys(retByNet).length) {
  //   if (Object.hasOwnProperty.call(retByNet, "not")) {
  //     result.push(`byNet=${retByNet.not}`);
  //   } else {
  //     const net_from = retByNet.from ? retByNet.from : 0;
  //     const net_to = retByNet.to ? `,${retByNet.to}` : "";
  //     result.push(`byNet=${net_from}${net_to}`);
  //   }
  // }

  // if (retByEnh.length) {
  //   result = [...result, ...retByEnh];
  // }
  if (retByCus.length) {
    // result = [...result, ...retByCus];
    result.push(...retByCus);
  }
  if (retByBrkEnh.length) {
    // result = [...result, ...retByBrkEnh];
    result.push(`byBroker[id]=${retByBrkEnh.join(",")}`);
  }

  // [ 'byAttribute[id]=1', 'byAttribute[producer]=GAA,SER,GAR', 'byAttribute[NAME]=Mario', 'byAttribute[ISPL.CAP]=23807', 'byRegistryGroup[code]=CWJ']
  // NOTE: byAttribute[ISPL.CAP]=23807 => NON sarà così... wallace deve ancora deliberare...
  // NOTE: byAttribute[producer]=GAA,SER,GAR => per ora solo per gli ID può funzionare perché le stringhe passano dal LIKE "value%" unico
  //      potrebbe invece essere byProducer[id]=1,23,456 a patto che esista la relazione col Producer (e l'implementazione del relativo filtro)
  return result.join("&");
};

export const prepareRelationQueryString = (arr, label = "relations") => {
  return `${label}=${arr.join(",")}`;
};

export const removeEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === "object" ? removeEmpty(v) : v))
      .filter((v) => !["", null, undefined].includes(v));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? removeEmpty(v) : v])
      .reduce(
        (a, [k, v]) =>
          ["", null, undefined].includes(v) ? a : ((a[k] = v), a),
        {}
      );
  }
};

export const changeObjectToDotNotationFormat = (
  inputObject,
  current,
  prefinalObject
) => {
  // console.info("changeObjectToDotNotationFormat");
  const result = prefinalObject ? prefinalObject : {}; // This allows us to use the most recent result object in the recursive call
  for (const key in inputObject) {
    let value = inputObject[key];
    let newKey = current ? `${current}.${key}` : key;
    if (value && Array.isArray(value) && value.length) {
      // console.debug("changeObjectToDotNotationFormat array");
      result[newKey] = value.join(",");
    } else if (value && typeof value === "object") {
      changeObjectToDotNotationFormat(value, newKey, result);
    } else {
      // skip empty values...
      //   if (!["", null, undefined].includes(value)) {
      result[newKey] = value;
      //   }
    }
  }
  return result;
};

export const nullifyObjectProps = (obj, except = [], force = []) => {
  for (const prop in obj) {
    if (obj[prop] == null) {
      continue;
    }
    if (force.includes(prop)) {
      obj[prop] = null;
      continue;
    }
    if (Array.isArray(obj[prop])) {
      obj[prop] = [];
    } else if (typeof obj[prop] === "object")
      nullifyObjectProps(obj[prop], except, force);
    else {
      if (except.includes(prop)) {
        continue;
      }
      obj[prop] = null;
    }
  }
  return obj;
};

// module.exports = {
//   reduceByKeys,
//   removeKeys,
//   getFormFieldsKeys,
//   getInputType,
//   setFormValues,
//   getResource,
//   prepareFilterQueryString,
//   prepareRelationQueryString,
//   removeEmpty,
//   changeObjectToDotNotationFormat,
//   nullifyObjectProps,
// };
