import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueSidebarMenu from "vue-sidebar-menu";
Vue.use(VueSidebarMenu);

import "./assets/css/custom.scss";
import "./assets/themify-icons-font/themify-icons/themify-icons.css";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import "./vee-validate";
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import "node-snackbar/src/sass/snackbar.sass";
import { showSnackbar } from "@/utils/messages";
import { getErrorMessage } from "@/utils/errors";

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

import MonthlyPicker from "vue-monthly-picker";
Vue.component("monthpicker", MonthlyPicker);

import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
Vue.use(VueFormWizard);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString().replaceAll("_", " ");
  return value.charAt(0).toUpperCase() + value.slice(1);
});

// const downloadDocument = (id, title, extension) => {
//   console.log("downloadDocument", id, itle, extension);
// }

const plugin = {
  install(Vue) {
    Vue.prototype.$getErrorMessage = getErrorMessage;
    Vue.prototype.$showSnackbar = showSnackbar;
  },
};
Vue.use(plugin);

new Vue({
  /* data: {
    // args: {},
    newReports: [],
    items: [],
  },
  methods: {
    // goToReport: function (report) {
    //   this.$router.push(this.args[report]);
    // },
    downloadPollDocument(id, title, extension) {
      // NOTE: enable window.downloadPollDocument = this.downloadPollDocument; in created() hook!!!
      console.log("downloadPollDocument", id, title, extension);
      // override DocumentsMixin "downloadDocument()" method (cannot use mixins here!)
      this.$axios
        .get(`/documents/download/${id}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${title}.${extension}`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    loadData: function () {
      const loggedIn = store.getters["auth/isLogged"];
      const userId = store.state.auth.user.id;
      if (loggedIn) {
        console.log("POLLING.........................");
        this.$axios
          .get(
            `/generated_reports?byReport&byDocument&byUser[id]=${userId}&byAttribute[is_ready]=Y`
          )
          .then((response) => {
            this.items = response.data.data;
            console.log(this.items);
            this.newReports = [];
            // WARNING:
            // dal momento che il contesto non è una vista Vue, un eventuale evento click sulla Snackbar
            // sarebbe quello nativo (onClick), cioè non è possibile usare @click.
            // Questo limite impedisce poi di poter eseguire la chiamata shortcut o a qualsiasi metodo (goToReport) definito
            // in questa inizializzazione di Vue.
            // this.newReports.push(
            //   "<div onClick=\"shortcut('module.PFOLIO', null, '#Registries', 'filterRegistry', { 'attributables.SURN': 'Albani', })\" >REPORT 1</div>"
            // );
            // this.args = {};
            // this.args["REPORT 1"] = {};
            // this.args["REPORT 1"].name = "module.PFOLIO";
            // this.args["REPORT 1"].hash = "#Registries";
            // this.args["REPORT 1"].criteria = { "attributables.SURN": "Albani" };

            // this.newReports.push(
            //   `<div onClick="this.goToReport('REPORT 1')">REPORT 1</div>`
            // );
            this.newReports.push("<h5>Report Pronti</h5>");
            this.newReports.push("<ul>");
            this.items.forEach((element) => {
              let document = element.documents[0];
              this.newReports.push(
                `<li>${document.formatted_title}
                <a href="#" onClick="downloadPollDocument(${document.id}, '${document.formatted_title}', '${document.extension}')"> scarica</a>
                </li>`
              );
            });
            this.newReports.push("</ul>");
            if (this.newReports.length) {
              this.$showSnackbar({
                pos: "top-right",
                preset: "success",
                // text: this.newReports.join("<br>"),
                text: this.newReports.join(""),
                actionText: "OK",
                duration: 10000,
              });
            }
          });
      }
    },
  },
  mounted: function () {
    this.loadData();
    setInterval(
      function () {
        this.loadData();
      }.bind(this),
      30000
    );
  }, */
  router,
  store,
  created() {
    // window.downloadPollDocument = this.downloadPollDocument;
    this.$axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          // no internet or server down
          console.info("Please check your internet connection.");
          return Promise.reject("Please check your internet connection.");
        }
        console.error(`interceptor error status: ${error.response.status}`);
        console.error(
          `interceptor error data.message: ${JSON.stringify(
            error.response.data.message
          )}`
        );
        switch (error.response.status) {
          case 401: // Not logged in
            // backend states Unauthorized, need to commit AUTH_LOGOUT for router.beforeEach correct handling
            this.$store.commit("auth/AUTH_LOGOUT");
            // cannot call dispatch logout or we get a redirect loop
            // store.dispatch("auth/logout").then(() => {
            this.$router
              .replace({
                name: "Login",
              })
              .catch(() => {}); // workaround to avoid redundant navigation error
            // this.$router.go(-1);
            // this.$router
            //   .push({
            //     name: "Login",
            //   })
            //   .catch(() => {});
            // });
            // return Promise.reject("Unauthorized");
            return Promise.reject(error.response);
          case 403:
            // // override the original message
            // error.response.data.message = "Azione non consentita";
            return Promise.reject(error.response);
          // return Promise.reject("Azione non consentita");
          // showSnackbar({
          //   pos: "top-center",
          //   text: error.response.data.messsage,
          // });
          // break;
          case 404: // not found
            return Promise.reject(error.response);
          case 419: // Session expired
            // this.$store.commit("auth/logout");
            this.$store
              .dispatch("auth/logout")
              .then(() => {
                this.$router.replace({
                  name: "Login",
                });
              })
              .catch(() => {}); // workaround to avoid redundant navigation error
            return Promise.reject(error.response);
          case 422:
            return Promise.reject(error.response);
          // break;
          case 503: // Down for maintenance
            // Bounce the user to the login screen with a redirect back
            // this.$store.dispatch("auth/logout").then(() => {
            //   this.$router.replace({
            //     name: "Login",
            //   });
            // });
            // break;
            this.$store.commit("auth/AUTH_LOGOUT");
            // Redirect the user to a Maintenance page
            this.$router.replace({
              name: "maintenance",
            });
            return Promise.reject(error.response);
          case 500:
            this.$showSnackbar({
              preset: "error",
              text: "Oops, something went wrong!",
            });
            return Promise.reject(error.response);
          // break;
          default:
            // Allow individual requests to handle other errors
            return Promise.reject(error.response);
        }
        //   if (error.response.status === 419) {
        //       // expired
        //       this.$store.commit('auth/clearUserData')
        //   }
        //   if (error.response.status === 401) {
        //     //   this.$store.commit('auth/clearUserData')
        //     //   this.$router.push({
        //     //         name: "Login"
        //     //    });
        //     this.$store.dispatch('auth/logout')
        //     .then(() => {
        //         this.$router.push({
        //             name: "Login"
        //         });
        //     })
        // .catch((error) => {
        //   let errMsg = this.$getErrorMessage(error);
        //   this.$showSnackbar({
        //     preset: "error",
        //     text: `${errMsg}`,
        //   });
        //   }
        //   if (error.response.status === 404) {
        //       console.error("User not found")
        //       return Promise.reject("User not found")
        //   }
        //   return Promise.reject(error)
      }
    );
  },
  render: (h) => h(App),
}).$mount("#app");
