import Repository from "@/repositories/Repository";

const resource = "/conti-prima-nota";

export default {
  index(queryString) {
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  create() {
    return Repository.get(`${resource}/crea`);
  },
  edit(id) {
    // get the edit form
    return Repository.get(`${resource}/${id}/edit`);
  },
  validate(field, payload) {
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    // delete the item
    return Repository.delete(`${resource}/${id}`);
  },
};
