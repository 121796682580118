const tabs = {
  namespaced: true,
  state: {
    status: "",
    conf: {},
  },

  mutations: {
    TAB_SAVE(state, { name, selected }) {
      state.conf[name] = selected;
      state.status = "saved";
    },
    TAB_REMOVE(state, name) {
      delete state.conf[name];
      state.status = "removed";
    },
    TAB_REMOVE_ALL(state) {
      state.conf = {};
      state.status = "removed all";
    },
    TAB_ERROR(state) {
      state.status = "error";
    },
  },

  actions: {
    saveByName({ commit }, obj) {
      // console.debug("tabs action saveByName", obj);
      commit("TAB_SAVE", obj);
    },
    removeByName({ commit }, name) {
      // console.debug("tabs action removeByName", name);
      commit("TAB_REMOVE", name);
    },
    removeAll({ commit }) {
      // console.debug("tabs action removeAll");
      commit("TAB_REMOVE_ALL");
    },
  },

  getters: {
    loadByName: (state) => (name) => {
      //name is a parameter for the getter
      // console.debug("tabs getter loadByName", name);
      return state.conf[name];
    },
  },
};
export default tabs;
