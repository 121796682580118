import { deepEqual } from "@/utils/objects";

const cache = {
  namespaced: true,
  state: {
    data: {},
  },

  mutations: {
    CACHE_SAVE(state, { name, cache }) {
      state.data[name] = cache;
      state.status = "saved";
    },
    CACHE_REMOVE(state, name) {
      delete state.data[name];
      state.status = "removed";
    },
  },

  actions: {
    saveByName({ commit }, obj) {
      // console.debug("cache action saveByName", obj);
      commit("CACHE_SAVE", obj);
    },
    removeByName({ commit }, name) {
      // console.debug("cache action removeByName", name);
      commit("CACHE_REMOVE", name);
    },
  },

  getters: {
    get: (state) => (name, qs) => {
      return state.data[name] && deepEqual(state.data[name].qs, qs)
        ? state.data[name]
        : null;
    },
  },
};
export default cache;
