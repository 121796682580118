import store from "@/store";
import { toLocaleDate } from "@/utils/dates";
import { toLocaleCurrency } from "@/utils/strings";
import { groupBy } from "@/utils/accounting";
import { getDictionary } from "@/utils/dictionary";
// const councils = store.state.auth.councils;
// const countries = store.state.auth.countries;
// const mandate_codes = store.state.auth.mandate_codes;
const provinceByCouncil = store.getters["auth/provinceByCouncil"];
const countryByCouncil = store.getters["auth/countryByCouncil"];

export const toRelationString = (
  relationName,
  array,
  fields,
  statuses = [0]
) => {
  let ret = [];
  let data = [];
  let statusName;
  switch (relationName) {
    case "brokers":
      statusName = "status_broker";
      break;
    case "registries":
      statusName = "status_registry";
      break;
    case "correspondences":
      statusName = "status_correspondence";
      break;
    case "address_books":
      statusName = "status_addressbook"; // <= notare l'assenza di underscore!!!
      break;
  }
  if (!Array.isArray(array)) {
    array = [array];
  }
  if (statusName) {
    // il default deve esserci
    if (statuses === null) {
      statuses = [0];
    }
    data = array.filter((el) => statuses.includes(el[statusName].value));
  } else {
    data = array;
  }

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < fields.length; j++) {
      let parts = fields[j].split("|");
      let text = [];
      for (let k = 0; k < parts.length; k++) {
        let t;
        const parts2 = parts[k].split(".");
        // devo risolvere il codice comune con la label
        // switch (parts2.slice(-2).join(".")) {
        //   case "attributables.POB":
        //   case "attributables.CITY":
        //   case "attributables.ISPL":
        switch (parts[k]) {
          case "CITY":
          case "POB":
          case "ISPL":
            t = parts2.reduce(
              (acc, part) => acc && acc[part],
              data[i].attributables
            );

            t = store.state.auth.councils.find((e) => e.id == t);
            if (t.code == "ZZ") {
              t = ["CITY2"].reduce(
                (acc, part) => acc && acc[part],
                data[i].attributables
              );
            } else {
              t = t.title;
            }
            break;
          // vitual fields
          case "VIRTUAL_CITY_PROVINCE":
            t = ["CITY"].reduce(
              (acc, part) => acc && acc[part],
              data[i].attributables
            );
            t = `${provinceByCouncil(t, "code")}`;
            break;
          case "VIRTUAL_POB_PROVINCE":
            t = ["POB"].reduce(
              (acc, part) => acc && acc[part],
              data[i].attributables
            );
            t = provinceByCouncil(t, "code");
            break;
          case "VIRTUAL_FULLNAME":
            if (data[i][statusName].value === 0) {
              t = `${["SURN"].reduce(
                (acc, part) => acc && acc[part],
                data[i].attributables
              )} ${["NAME"].reduce(
                (acc, part) => acc && acc[part],
                data[i].attributables
              )}`;
            } else if (data[i][statusName].value === 1) {
              t = ["CNAM"].reduce(
                (acc, part) => acc && acc[part],
                data[i].attributables
              );
            }
            break;
          case "VIRTUAL_POB_NATION":
            t = ["POB"].reduce(
              (acc, part) => acc && acc[part],
              data[i].attributables
            );
            t = countryByCouncil(t, "title");
            break;
          case "VIRTUAL_CITY_NATION":
            t = ["CITY"].reduce(
              (acc, part) => acc && acc[part],
              data[i].attributables
            );
            t = store.state.auth.councils.find((e) => e.id == t);
            if (t.code == "ZZ") {
              t = ["NOR"].reduce(
                (acc, part) => acc && acc[part],
                data[i].attributables
              );
              if (t) {
                t = store.state.auth.countries.find((e) => e.id == t).title;
              } else {
                t = data[i].attributables["CITY2"];
              }
            } else {
              t = countryByCouncil(t.id, "title");
            }
            break;
          default:
            t = parts2.reduce(
              (acc, part) => acc && acc[part],
              data[i].attributables
            );
        }
        if (t) {
          text.push(t);
        }
      }
      ret.push(text.join(" "));
    }
  }
  return ret.join(",");
};

export const toInfoData = (data, type, status = null, sep = "<br/>") => {
  let ret = [];
  let value;
  let tmp;
  if (!data) {
    return "";
  }
  let statuses = status !== null ? [status] : status;
  switch (type) {
    case "brokerfullname":
      if (data.attributables.NAME && data.attributables.SURN) {
        value = `${toRelationString(
          "brokers",
          data,
          ["SURN"],
          statuses
        )} ${toRelationString("brokers", data, ["NAME"], statuses)}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      }
      if (data.attributables.CNAM) {
        value = toRelationString("brokers", data, ["CNAM"], statuses);
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      }
      break;
    case "broker":
      if (
        data.attributables.BUID &&
        data.attributables.NAME &&
        data.attributables.SURN
      ) {
        value = `${toRelationString(
          "brokers",
          data,
          ["BUID"],
          statuses
        )} ${toRelationString(
          "brokers",
          data,
          ["SURN"],
          statuses
        )} ${toRelationString("brokers", data, ["NAME"], statuses)}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      }
      if (data.attributables.BUID && data.attributables.CNAM) {
        value = `${toRelationString(
          "brokers",
          data,
          ["BUID"],
          statuses
        )} ${toRelationString("brokers", data, ["CNAM"], statuses)}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      }
      break;
    case "address":
      // ADDR
      value = `${toRelationString(
        "correspondences",
        data.correspondences,
        ["ADDR"],
        statuses
      )}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(value);
      }
      // ZIPC CITY (VIRTUAL_CITY_PROVINCE)
      tmp = `${toRelationString(
        "correspondences",
        data.correspondences,
        ["VIRTUAL_CITY_PROVINCE"],
        statuses
      ).toUpperCase()}`;
      if (tmp) {
        tmp = `(${tmp})`;
      } else {
        tmp = "-";
      }

      value = `${toRelationString(
        "correspondences",
        data.correspondences,
        ["ZIPC"],
        statuses
      )} ${toRelationString(
        "correspondences",
        data.correspondences,
        ["CITY"],
        statuses
      )} ${tmp} ${toRelationString(
        "correspondences",
        data.correspondences,
        ["VIRTUAL_CITY_NATION"],
        statuses
      )}`;
      if (value.replace(/[\s()-]/gm, "")) {
        ret.push(value);
      }
      break;
    case "registryfullname":
      // SURN NAME or CNAM
      if (data.status_registry.value === (status === null ? 0 : status)) {
        value = `${toRelationString(null, data, ["SURN"])} ${toRelationString(
          null,
          data,
          ["NAME"]
        )}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      } else if (
        data.status_registry.value === (status === null ? 1 : status)
      ) {
        value = `${toRelationString(null, data, ["CNAM"])}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      }
      break;
    case "registry":
      // SURN NAME or CNAM
      if (data.status_registry.value === (status === null ? 0 : status)) {
        value = `${toRelationString(null, data, ["SURN"])} ${toRelationString(
          null,
          data,
          ["NAME"]
        )}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      } else if (
        data.status_registry.value === (status === null ? 1 : status)
      ) {
        value = `${toRelationString(null, data, ["CNAM"])}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(value);
        }
      }
      // per l'indirizzo "status" non può essere null, carico sempre la residenza
      // ADDR
      value = `${toRelationString(
        "correspondences",
        data.correspondences,
        ["ADDR"],
        [0]
      )}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(value);
      }
      // ZIPC CITY (VIRTUAL_CITY_PROVINCE)
      tmp = `${toRelationString(
        "correspondences",
        data.correspondences,
        ["VIRTUAL_CITY_PROVINCE"],
        [0]
      ).toUpperCase()}`;
      if (tmp) {
        tmp = `(${tmp})`;
      } else {
        tmp = "-";
      }

      value = `${toRelationString(
        "correspondences",
        data.correspondences,
        ["ZIPC"],
        [0]
      )} ${toRelationString(
        "correspondences",
        data.correspondences,
        ["CITY"],
        [0]
      )} ${tmp} ${toRelationString(
        "correspondences",
        data.correspondences,
        ["VIRTUAL_CITY_NATION"],
        [0]
      )}`;
      if (value.replace(/[\s()-]/gm, "")) {
        ret.push(value);
      }
      break;
    case "insurance_ancillary":
      // descrizione
      value = data.title || "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`Titolo: ${value}`);
      }
      // number
      value = data.insurance_policy.number || "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`Polizza: ${value}`);
      }

      break;
    case "insurance_policy":
      // number
      value = data.number || "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`Numero: ${value}`);
      }
      // titolo
      value = data.title || "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`Descrizione: ${value}`);
      }
      // codice mandato
      // value = data.mandate_code || "";
      tmp = store.state.auth.mandate_codes.find(
        (e) => e.id === data.mandate_code_id
      );
      value = "";
      if (tmp) {
        value = tmp.code;
      }
      if (value.replace(/\s/gm, "")) {
        ret.push(`Codice Mandato: ${value}`);
      }
      // SURN NAME or CNAM
      if (
        data.registry.status_registry.value === (status === null ? 0 : status)
      ) {
        value = `${toRelationString(null, data.registry, [
          "SURN",
        ])} ${toRelationString(null, data.registry, ["NAME"])}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(`Contraente: ${value}`);
        }
      } else {
        value = `${toRelationString(null, data.registry, ["CNAM"])}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(`Contraente: ${value}`);
        }
      }
      break;
    case "insurance_policy_ext1":
      // Contraente:
      // NOMINATIVO
      // INDIRIZZO CAP LOCALITA' PROVINCIA
      // C.F.
      // P.IVA
      // TEL.
      // CELL
      // MAIL
      // PEC

      // NOMINATIVO
      if (
        data.registry.status_registry.value === (status === null ? 0 : status)
      ) {
        value = `${toRelationString(null, data.registry, [
          "SURN",
        ])} ${toRelationString(null, data.registry, ["NAME"])}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(`NOMINATIVO: ${value}`);
        }
      } else {
        value = `${toRelationString(null, data.registry, ["CNAM"])}`;
        if (value.replace(/\s/gm, "")) {
          ret.push(`NOMINATIVO: ${value}`);
        }
      }
      // per l'indirizzo "status" non può essere null, carico sempre la residenza
      // INDIRIZZO CAP LOCALITA' PROVINCIA
      // ADDR
      value = `${toRelationString(
        "correspondences",
        data.registry.correspondences,
        ["ADDR"],
        [0]
      )}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`INDIRIZZO: ${value}`);
      }
      // ZIPC
      value = toRelationString(
        "correspondences",
        data.registry.correspondences,
        ["ZIPC"],
        [0]
      );
      if (value.replace(/\s/gm, "")) {
        ret.push(`CAP: ${value}`);
      }
      // CITY
      value = toRelationString(
        "correspondences",
        data.registry.correspondences,
        ["CITY"],
        [0]
      );
      if (value.replace(/\s/gm, "")) {
        ret.push(`LOCALITA': ${value}`);
      }
      // VIRTUAL_CITY_PROVINCE;
      value = toRelationString(
        "correspondences",
        data.registry.correspondences,
        ["VIRTUAL_CITY_PROVINCE"],
        [0]
      ).toUpperCase();
      if (value.replace(/\s/gm, "")) {
        ret.push(`PROVINCIA: ${value}`);
      }
      // C.F.
      value = toRelationString(null, data.registry, ["NINO"], 0);
      if (value.replace(/\s/gm, "")) {
        ret.push(`C.F.: ${value}`);
      }
      // P.IVA
      value = toRelationString(null, data.registry, ["CREG"], 1);
      if (value.replace(/\s/gm, "")) {
        ret.push(`P.IVA: ${value}`);
      }
      // TEL.
      value = `${toRelationString(null, data.registry.address_books, [
        "PHON",
      ])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`TEL.: ${value}`);
      }
      // CELL
      value = `${toRelationString(null, data.registry.address_books, [
        "CELL",
      ])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`CELL: ${value}`);
      }
      // MAIL
      value = `${toRelationString(null, data.registry.address_books, [
        "MAIL",
      ])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`MAIL: ${value}`);
      }
      // PEC
      value = `${toRelationString(null, data.registry.address_books, ["PEC"])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`PEC: ${value}`);
      }
      break;
    case "insurance_policy_ext2":
      // Polizza:
      // CODICE COMPAGNIA
      // NUMERO POLIZZA
      // RAMO
      // PRODOTTO
      // DATA COPERTURA
      // DATA SCADENZA
      // STATO POLIZZA
      // CODICE + DESCR. PRODUTTORE

      // CODICE COMPAGNIA
      value = data.insurer ? data.insurer.code : "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`CODICE COMPAGNIA: ${value}`);
      }
      // NUMERO POLIZZA
      value = data.number || "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`NUMERO POLIZZA: ${value}`);
      }
      // RAMO
      value = data.insurance_risk.risk_branch.formatted_title || "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`RAMO: ${value}`);
      }
      // PRODOTTO
      value = data.insurance_risk.formatted_title || "";
      if (value.replace(/\s/gm, "")) {
        ret.push(`PRODOTTO: ${value}`);
      }
      // DATA COPERTURA
      value = toLocaleDate(data.covered_at);
      if (value.replace(/\s/gm, "")) {
        ret.push(`DATA COPERTURA: ${value}`);
      }
      // DATA SCADENZA
      value = toLocaleDate(data.expired_at);
      if (value.replace(/\s/gm, "")) {
        ret.push(`DATA SCADENZA: ${value}`);
      }
      // STATO POLIZZA
      value = data.status_policy.text;
      if (value.replace(/\s/gm, "")) {
        ret.push(`STATO POLIZZA: ${value}`);
      }
      // CODICE + DESCR. PRODUTTORE
      value = data.broker
        ? `${toRelationString(null, data.broker, ["BUID"])} ${
            data.broker.formatted_title
          }`
        : "N/A";

      if (value.replace(/\s/gm, "")) {
        ret.push(`PRODUTTORE: ${value}`);
      }

      break;
    case "agency_contacts":
      // SURN NAME
      value = `${toRelationString(null, data, ["SURN"])} ${toRelationString(
        null,
        data,
        ["NAME"]
      )}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`Cognome/Nome: ${value}`);
      }
      // CNAM
      value = toRelationString(null, data, ["CREG"]);
      if (value.replace(/\s/gm, "")) {
        ret.push(`Partita IVA: ${value}`);
      }
      // NINO
      value = toRelationString(null, data, ["NINO"]);
      if (value.replace(/\s/gm, "")) {
        ret.push(`Cod.Fiscale: ${value}`);
      }
      // CREG
      value = toRelationString(null, data, ["CNAM"]);
      if (value.replace(/\s/gm, "")) {
        ret.push(`Rag.Sociale: ${value}`);
      }
      break;
    case "address_books":
      // TEL.
      value = `${toRelationString(null, data.address_books, ["PHON"])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`Tel.: ${value}`);
      }
      // CELL
      value = `${toRelationString(null, data.address_books, ["CELL"])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`Mobile: ${value}`);
      }
      // MAIL
      value = `${toRelationString(null, data.address_books, ["MAIL"])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`Email: ${value}`);
      }
      // PEC
      value = `${toRelationString(null, data.address_books, ["PEC"])}`;
      if (value.replace(/\s/gm, "")) {
        ret.push(`PEC: ${value}`);
      }
      break;
  }
  return ret.join(sep);
};

export const toTooltipTask = (
  array,
  header,
  fields = [
    { label: "Titolo", key: "title" },
    { label: "Descrizione", key: "description" },
    {
      label: "Ultimo aggiornamento",
      key: "last_update",
    },
  ]
) => {
  let ret = {
    title: "",
    placement: "left",
    html: true /*trigger: 'click hover'*/,
  };
  if (!array || !array.length) {
    return ret;
  }
  let content = "<table class='amount-tooltip'><thead>";
  // thead
  content += "<tr>";
  for (const field of fields) {
    content += `<th>${field.label}</th>`;
  }
  content += "</tr></thead><tbody>";
  // tbody
  for (const obj of array) {
    content += "<tr>";
    for (const field of fields) {
      let formatted;
      if (field.formatter instanceof Function) {
        formatted = field.formatter(obj[field.key]);
      }
      content += `<td>${
        obj[field.key] != null ? (formatted ? formatted : obj[field.key]) : ""
      }</td>`;
    }
    content += "</tr>";
  }
  content += "</tbody></table>";
  ret.title = "<b>" + header + "</b><br/>" + content;
  return ret;
};

export const toGroupedTooltipTask = (
  array,
  fields = [
    // { label: "Entità", key: "entity" },
    { label: "Titolo", key: "title" },
    { label: "Descrizione", key: "description" },
    { label: "Ultimo aggiornamento", key: "last_update" },
  ]
) => {
  let ret = {
    title: "",
    placement: "left",
    html: true /*trigger: 'click hover'*/,
  };
  if (!array || !array.length) {
    return ret;
  }
  let content = "<table class='amount-tooltip'><thead>";
  // thead
  content += "<tr>";
  // add static th(Entità)
  content += `<th style="text-align: left">Entità</th>`;
  // add all others
  for (const field of fields) {
    content += `<th style="text-align: left">${field.label}</th>`;
  }
  content += "</tr></thead><tbody>";
  // #573 disinguere le note per entità: pivot.taskable_type
  // const groupByTaskable = groupBy("taskable_type", "pivot");
  const groupByTaskable = groupBy("pivot.taskable_type");
  const dataByTaskable = groupByTaskable(array);
  for (const [taskable, tasks] of Object.entries(dataByTaskable)) {
    let header = getTaskableLabel(taskable);
    // content +=
    //   `<tr><td style="text-align: left" colspan="${fields.length}">` +
    //   header +
    //   "</td></tr>";
    // tbody
    for (const obj of tasks) {
      content += "<tr>";
      // add static td
      content += `<td style="text-align: left">${header}</td>`;
      // add all others
      for (const field of fields) {
        content += `<td style="text-align: left">${
          obj[field.key] != null ? obj[field.key] : ""
        }</td>`;
      }
      content += "</tr>";
    }
  }
  content += "</tbody></table>";
  ret.title = content;
  return ret;
};
/**
 * 
 * @param {*} data 
 * @returns Array
 *   data:
 *  "broker_wht": "4.6",      percentuale WHT del broker
    "gross": 2820,            totale entryDetails.gross
    "deferred": -3,           entryDetails.treasury = SS
    "deposit": 0,             entryDetails.treasury = AC
    "purchase": 9.27,         saler_prov_purchase
    "take": 42.35,            saler_prov_take
    "fees": 0,                saler_fee_take
    "purchase_tax": 9.27,     idem dove taxable = Y
    "take_tax": 42.35,        idem dove taxable = Y
    "deduction": 0,           differenza prov * percentuale
    "premium": 2768.38,       totale finale
 *
 *  logic:
    this.summary.ritenuta_perc = parseFloat(data.broker_wht);
    this.summary.entrate = data.gross;
    this.summary.sospesi = data.deferred;
    this.summary.acconti = data.deposit;
    this.summary.provvigioni = data.purchase + data.take;
    this.summary.di_cui = data.purchase_tax + data.take_tax;
    this.summary.di_cui_diritti = data.fees;
    this.summary.ritenuta = data.deduction;
    this.summary.da_liquidare = data.purchase + data.take - data.deduction;
    this.summary.saldo =
      data.gross -
      data.deposit -
      (data.purchase + data.take) +
      data.deduction;
 *
      returns
    [
      { [this.getDictionary("premiums")]: this.summary.entrate },
      {
        [this.getDictionary("of_which_deferred_to_collect")]: this.summary
          .sospesi,
      },
      { [this.getDictionary("advance_payments")]: this.summary.acconti },
      { [this.getDictionary("commissions")]: this.summary.provvigioni },
      { [this.getDictionary("of_which_not_taxable")]: this.summary.di_cui },
      { [this.getDictionary("fees")]: this.summary.di_cui_diritti }, // #1112
      {
        [`${this.getDictionary("deduction")} (${
          this.summary.ritenuta_perc
        }%)`]: this.summary.ritenuta,
      },
      {
        [this.getDictionary("commissions_to_liquidate")]: this.summary
          .da_liquidare,
      },
      { [this.getDictionary("premiums_balance")]: this.summary.saldo },
    ]
 */
const transformTotalizers = (data) => {
  let ret = [];
  ret.push({ [getDictionary("premiums")]: data.gross });
  ret.push({
    [getDictionary("of_which_deferred_to_collect")]: data.deferred,
  });
  ret.push({ [getDictionary("advance_payments")]: data.deposit });
  ret.push({ [getDictionary("commissions")]: data.purchase + data.take });
  ret.push({
    [getDictionary("of_which_not_taxable")]: data.purchase_tax + data.take_tax,
  });
  ret.push({ [getDictionary("fees")]: data.fees }); // #1112
  ret.push({
    [`${getDictionary("deduction")} (${data.broker_wht}%)`]: data.deduction,
  });
  ret.push({
    [getDictionary("commissions_to_liquidate")]:
      data.purchase + data.take - data.deduction + data.fees,
  });
  ret.push({
    [getDictionary("premiums_balance")]:
      data.gross /* - data.deposit */ -
      (data.purchase + data.take) +
      data.deduction -
      data.fees,
  });
  return ret;
};
/**
 *
 * @param {*} data Array|Object
 * @param {*} sep
 * @returns String
 */
export const toTotalizers = (data, sep = "<br/>") => {
  let t = [];
  // old fashion: data is a json "blob" stored in a db field as array of objects
  // REVIEW: in future "data" will always be an Object, so transformTotalizers has to be called every time
  if (!Array.isArray(data)) {
    // new fashion: data is broker_statement model (object)
    data = transformTotalizers(data);
  }
  // from now on, everything is same as before
  data.forEach((e) => {
    for (const [k, v] of Object.entries(e)) {
      t.push(`${k}: ${toLocaleCurrency(v)}`);
    }
  });
  return t.join(sep);
};

export const getTaskableLabel = (taskable) => {
  // "App\Models\Registry"
  let ret = "";
  let s = taskable.split("\\");
  switch (s[s.length - 1]) {
    case "Registry":
      ret = "Anagrafica";
      break;
    case "InsurancePolicy":
      ret = "Polizza";
      break;
    default:
      break;
  }
  return ret;
};

export const joinValues = (
  array = [],
  separator = "-",
  defaultNull = "",
  skipNull = false
) => {
  let tempArray = [];

  for (const element of array) {
    if (element) {
      tempArray.push(element);
    }
    if (element === null && !skipNull) {
      tempArray.push(defaultNull);
    }
  }

  return tempArray.join(separator);
};

export const findAndGet = (array = [], findField, value, returnField) => {
  const found = array.find((e) => e[findField] === value);
  return found ? found[returnField] : null;
};
// module.exports = {
//   toRelationString,
// };
