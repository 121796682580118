const quicksearch = {
  namespaced: true,
  state: {
    status: "",
    conf: {},
  },

  mutations: {
    FILTER_SAVE(state, { name, criteria }) {
      state.conf[name] = criteria;
      state.status = "saved";
    },
    FILTER_REMOVE(state, name) {
      delete state.conf[name];
      state.status = "removed";
    },
    FILTER_REMOVE_ALL(state) {
      state.conf = {};
      state.status = "removed all";
    },
    FILTER_ERROR(state) {
      state.status = "error";
    },
  },

  actions: {
    saveByName({ commit }, obj) {
      // console.debug("quicksearch action saveByName", obj);
      commit("FILTER_SAVE", obj);
    },
    removeByName({ commit }, name) {
      // console.debug("quicksearch action removeByName", name);
      commit("FILTER_REMOVE", name);
    },
    removeAll({ commit }) {
      // console.debug("quicksearch action removeAll");
      commit("FILTER_REMOVE_ALL");
    },
  },

  getters: {
    loadByName: (state) => (name) => {
      //name is a parameter for the getter
      // console.debug("quicksearch getter loadByName", name);
      return state.conf[name];
    },
  },
};
export default quicksearch;
