import Snackbar from "node-snackbar";
/**
 *
 * @param {*} args can contain any of the Snackbar props to render a custom snackbar
 *                  or preset: success|error|info to render predefined styled snackbar
 */
export const showSnackbar = (args = {}) => {
  let params = {};
  // common args both for custom or predefined snackbar
  params.text = args.text ? args.text : null;
  params.pos = args.pos ? args.pos : "top-center";
  params.customClass = args.customClass ? args.customClass : "";
  params.width = args.width ? args.width : "auto";
  params.showAction = args.showAction === false ? args.showAction : true;
  params.actionText = args.actionText ? args.actionText : "Chiudi";
  params.duration = args.duration !== undefined ? args.duration : 5000; // 0 is forever
  if (args.onActionClick) {
    params.onActionClick = () => {
      args.onActionClick();
    };
  }
  if (args.onClose) {
    params.onClose = () => {
      args.onClose();
    };
  }
  if (args.preset) {
    // predefined
    switch (args.preset) {
      case "success":
        params.textColor = "#FFFFFF";
        params.actionTextColor = "#4CAF50";
        params.backgroundColor = "#323232";
        break;
      case "error":
        // params.textColor = "#000000";
        // params.actionTextColor = "#FF5733";
        // params.backgroundColor = "#FFFFFF";
        // params.textColor = "#F9991E";
        // params.actionTextColor = "#FF5733";
        // params.backgroundColor = "#323232";
        params.textColor = "#F9991E";
        params.actionTextColor = "#FF5733";
        params.backgroundColor = "#323232";
        break;
      case "info":
        params.textColor = "#FFFFFF";
        params.actionTextColor = "#F9991E";
        params.backgroundColor = "#323232";
        break;
    }
  } else {
    // custom
    params.textColor = args.textColor ? args.textColor : "#FFFFFF";
    params.actionTextColor = args.actionTextColor
      ? args.actionTextColor
      : "#4CAF50";
    params.backgroundColor = args.backgroundColor
      ? args.backgroundColor
      : "#323232";
  }
  Snackbar.show(params);
};

// module.exports = {
//   showSnackbar,
// };
