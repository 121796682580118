/**
 * convert the input value into currency|blank string
 * @param {*} value the input number
 * @param {*} nullable when false, must return non-blank string if !value (i.e. when value equals 0 or null)
 * @param {*} locale the currecny locale format
 * @param {*} currency the currency name
 * @returns
 */
export const toLocaleCurrency = (
  value,
  nullable = false,
  locale = "it-IT",
  currency = "EUR"
) => {
  return value
    ? value.toLocaleString(locale, { style: "currency", currency: currency })
    : !nullable
    ? parseFloat("0.00001").toLocaleString(locale, {
        style: "currency",
        currency: currency,
      })
    : "";
};

export const toLocaleDecimal = (value, nullable = false, locale = "it-IT") => {
  return value
    ? new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumIntegerDigits: 2,
        minimumFractionDigits: 2,
      }).format(value)
    : !nullable
    ? new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumIntegerDigits: 2,
        minimumFractionDigits: 2,
      }).format(parseFloat("0.00001"))
    : "";
};

export const toLocaleInteger = (value, nullable = false, locale = "it-IT") => {
  return value
    ? new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumIntegerDigits: 1,
        maximumFractionDigits: 0,
      }).format(value)
    : !nullable
    ? new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumIntegerDigits: 1,
        maximumFractionDigits: 0,
      }).format(parseFloat("0.00001"))
    : "";
};

// module.exports = {
//   toLocaleCurrency,
// };
