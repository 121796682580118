import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/lisa_for_apps";

export default {
  index(queryString) {
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  update(id, payload) {
    return Repository.put(`${resource}/${id}`, payload);
  },
  // report(code, payload = null) {
  //   return Repository.put(`${resource}/${code}`, payload, {
  //     responseType: "blob",
  //   });
  // },
  report(payload = null) {
    return Repository.post(`${resource}`, payload, {
      responseType: "blob",
    });
  },
};
