<template>
  <div class="breadcrumb-wrapper">
    <b-breadcrumb>
      <b-breadcrumb-item class="link--icon" :to="{ name: 'module.HOME' }">
        <b-icon
          icon="house"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        <span>Home</span>
      </b-breadcrumb-item>
      <template v-if="bcrumbs">
        <b-breadcrumb-item
          v-for="(element, index) in bcrumbs"
          :key="index"
          :text="element.text"
          @click="shortcut(element.path, null, element.tab)"
          :class="`${!element.path ? 'no-link' : ''}`"
          :disabled="isLastElement(index) || hasDisabled(element)"
        >
        </b-breadcrumb-item>
      </template>
    </b-breadcrumb>
  </div>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { mapActions } from "vuex";
import ShortcutMixin from "@/mixins/ShortcutMixin";

export default {
  mixins: [ShortcutMixin],
  name: "Breadcrumbs",
  props: ["bcrumbs"],
  // data() {
  // return {
  // breadcrumblist: [],
  // lastCrumb: null,
  // };
  // },
  methods: {
    // ...mapGetters("queue", ["getQueue"]),
    ...mapActions("queue", [/* "popQueue", "pushQueue", */ "emptyQueue"]),
  },
  computed: {
    // filteredList() {
    //   debugger;
    //   return this.bcrumbs
    //     ? [...this.bcrumbs.slice(1, this.bcrumbs.length - 1)]
    //     : [];
    // },
  },
  // watch: {
  //   //Watch queue-store changes
  //   "$store.state.queue.items": {
  //     //If you want to watch nested elements, you need to use the deep flag in your watcher
  //     deep: true,
  //     handler() {
  //       this.breadcrumblist = this.getQueue();
  //       let len = this.breadcrumblist.length;
  //       this.lastCrumb =
  //         len === 1 ? { text: "Dashboard" } : this.breadcrumblist[len - 1];
  //     },
  //   },
  // },
  created() {},
};
</script>
<style lang="scss" scoped>
.breadcrumb-wrapper {
  width: 100%;
  text-align: center;
}
.breadcrumb {
  border-radius: 0rem;
  margin: 0px;
  padding-left: 2rem;
  background: 0;
  // background-color: #e1ddda;
}

:deep(ol.breadcrumb) {
  padding: 0 !important;
}

:deep(li.breadcrumb-item > a) {
  color: #524b426c !important;
  font-weight: bold;
}
.breadcrumb-item {
  &:not(.link--icon) {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 0 0 5px;
  }
  &.link--icon {
    a {
      text-decoration: none;
      span {
        text-decoration: underline;
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          span {
            text-decoration: none;
          }
        }
      }
    }
    svg {
      margin: 0 4px 0 0;
    }
  }
  ~ .breadcrumb-item {
    &:before {
      display: block;
      color: #524b426c;
      content: ">";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' viewBox='0 96 960 960' width='14'%3E%3Cpath d='m375 816-43-43 198-198-198-198 43-43 241 241-241 241Z' fill='%23524b426c'/%3E%3C/svg%3E");
      font-size: 0;
      width: 14px;
      height: 14px;
      padding: 0;
    }
  }
  &.no-link,
  &:last-of-type {
    &,
    a {
      &,
      span {
        text-decoration: none;
        pointer-events: none;
        touch-action: none;
        cursor: default;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }
  a {
    text-decoration: underline;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
