const sidebar = {
  namespaced: true,
  state: {
    status: "",
    navClose: true,
    // locked flag is more generally used to prevent App's components (accordion-sidebar, navbar and breadcrumbs)
    // to show up before the last login promise has been resolved (see App.vue)
    locked: false,
  },

  mutations: {
    SIDEBAR_SAVE(state, value) {
      state.navClose = value;
      state.status = "saved";
    },
    SIDEBAR_LOCKED_SAVE(state, value) {
      state.locked = value;
      state.status = "saved";
    },
  },

  actions: {
    saveNavbar({ commit }, value) {
      commit("SIDEBAR_SAVE", value);
    },
  },
  getters: {
    isNavClose: (state) => {
      return state.navClose;
    },
  },
};

export default sidebar;
