import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/reports";
export default {
  create() {
    // get the create form
    return Repository.get(`${resource}/create`);
  },
  edit(id) {
    // get the edit form
    return Repository.get(`${resource}/${id}/edit`);
  },
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  update(code, payload) {
    // update the item
    return Repository.put(`${resource}/${code}`, payload);
  },
  report(code, payload) {
    return Repository.put(`${resource}/customReports/${code}`, payload);
  },
  test() {
    return new Promise((resolve) => {
      let fields = {
        format: {
          type: "select",
          label: "Formato report",
          options: [
            { value: "csv", text: "csv" },
            { value: "pdf", text: "pdf" },
          ],
        },
        request_input: {
          mese_da: { type: "datepicker", label: "Mese da", options: [] },
          mese_a: { type: "datepicker", label: "Mese a", options: [] },
          includi_attivi: {
            type: "checkbox",
            label: "Attivi",
            options: [
              { value: "Y", text: "Includi" },
              { value: "N", text: "Escludi" },
            ],
          },
          clienti: [1],
        },
      };
      resolve({ data: { fields, rules: {} } });
    });
  },
};
