const pagination = {
  namespaced: true,
  state: {
    status: "",
    conf: {},
  },

  mutations: {
    PAGINATION_SAVE(state, { name, criteria }) {
      state.conf[name] = criteria;
      state.status = "saved";
    },
    PAGINATION_REMOVE(state, name) {
      delete state.conf[name];
      state.status = "removed";
    },
    PAGINATION_REMOVE_ALL(state) {
      state.conf = {};
      state.status = "removed all";
    },
    PAGINATION_ERROR(state) {
      state.status = "error";
    },
  },

  actions: {
    saveByName({ commit }, obj) {
      // console.debug("pagination action saveByName", obj);
      commit("PAGINATION_SAVE", obj);
    },
    removeByName({ commit }, name) {
      // console.debug("pagination action removeByName", name);
      commit("PAGINATION_REMOVE", name);
    },
    removeAll({ commit }) {
      // console.debug("pagination action removeAll");
      commit("PAGINATION_REMOVE_ALL");
    },
  },

  getters: {
    loadByName: (state) => (name) => {
      //name is a parameter for the getter
      // console.debug("sorts getter loadByName", name);
      return state.conf[name];
    },
  },
};
export default pagination;
