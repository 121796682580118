export default [
  {
    path: "/wallet",
    name: "module.PFOLIO",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "management" */ "@/views/wallet/Management.vue"
      ),
  },
  {
    path: "/wallet-generated-reports",
    name: "module.PFOLIO.REPG",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "REPG",
    },
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/wallet/reports.vue"),
  },
  {
    path: "/wallet-registries",
    name: "registries.index",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "registries" */ "@/views/wallet/registries/index.vue"
      ),
  },
  {
    path: "/wallet-registries/:id/edit",
    name: "registries.edit",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "registries" */ "@/views/wallet/registries/edit.vue"
      ),
  },
  {
    path: "/wallet-registries-create",
    name: "registries.create",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "registries" */ "@/views/wallet/registries/create.vue"
      ),
  },
  {
    path: "/wallet-policies",
    name: "insurance_policies.index",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance_policies" */ "@/views/wallet/policies/index.vue"
      ),
  },
  {
    path: "/wallet-policies-create",
    name: "insurance_policies.create",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance_policies" */ "@/views/wallet/policies/create.vue"
      ),
  },
  {
    path: "/wallet-policies-replace",
    name: "insurance_policies.replace",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance_policies" */ "@/views/wallet/policies/replace.vue"
      ),
  },
  {
    path: "/wallet-policies-transfer",
    name: "insurance_policies.transfer",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance_policies" */ "@/views/wallet/policies/transfer.vue"
      ),
  },
  {
    path: "/wallet-policies/:id/policies",
    name: "insurance_policies.edit",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance_policies" */ "@/views/wallet/policies/edit.vue"
      ),
  },

  {
    path: "/wallet-claims",
    name: "claims.index",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "claims" */ "@/views/wallet/claims/index.vue"
      ),
  },
  {
    path: "/wallet-claims-create",
    name: "claims.create",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "claims" */ "@/views/wallet/claims/create.vue"
      ),
  },
  {
    path: "/wallet-claims/:id/claims",
    name: "claims.edit",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(/* webpackChunkName: "claims" */ "@/views/wallet/claims/edit.vue"),
  },

  {
    path: "/wallet-agency-contacts",
    name: "agency_contacts.index0",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_contacts" */ "@/views/wallet/agency-contacts/index.vue"
      ),
  },
  {
    path: "/wallet-agency-contacts-create",
    name: "agency_contacts.create0",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_contacts" */ "@/views/wallet/agency-contacts/create.vue"
      ),
  },
  {
    path: "/wallet-agency-contacts/:id/agency-contacts",
    name: "agency_contacts.edit0",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency_contacts" */ "@/views/wallet/agency-contacts/edit.vue"
      ),
  },

  {
    path: "/wallet-registry-groups",
    name: "registry_groups.index",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "registry-groups" */ "@/views/wallet/registry-groups/index.vue"
      ),
  },
  {
    path: "/wallet-registry-groups-create",
    name: "registry_groups.create",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "registry-groups" */ "@/views/wallet/registry-groups/create.vue"
      ),
  },
  {
    path: "/wallet-registry-groups/:id/registry-groups",
    name: "registry_groups.edit",
    meta: {
      auth: true,
      module: "PFOLIO",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "registry-groups" */ "@/views/wallet/registry-groups/edit.vue"
      ),
  },
];
