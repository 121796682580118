/**
 * transform laravel rules array into vee-validate rules object
 *
 * @param {Array} rules
 * @returns {Object}
 */
export const prepareRules = (rules) => {
  let obj = {};
  const minmax = /^(min|max)\s*:\s*(\d+)/g;
  // between:0.00,99.99
  const between = /^(between)\s*:\s*([0-9]+\.?[0-9]*)\s*,\s*([0-9]+\.?[0-9]*)/g;
  // required_if:status_registry,0
  const requiredif = /^required_if:(\w+),(.+)/;
  // required_with:ended_at
  const requiredwith = /^required_with:(\w+)/;
  // before: today oppure before: ended_at
  // before_or_equal: today oppure before_or_equal: ended_at
  // after: today oppure after: ended_at
  // after_or_equal: today oppure adter_or_equal: ended_at
  const afterbefore =
    /^(before|before_or_equal|after|after_or_equal)\s*:\s*(\w+)/g;
  const basic = ["required", "email"]; //basic laravel rules same ad vee-validate rules
  const mapped = { AttributeRequiredRule: "required" };
  // gt: N
  const op = /^(gt|gte|lt|lte)\s*:\s*(\d+)/g;
  // in:1,23,456
  const eq = /^(in|not_in)\s*:\s*(.+)/g;

  // let enhancedValues;
  for (const r of rules) {
    if (r === "string" || r === 'nullable' || r === 'date_format:Y-m-d' || (typeof r === 'object' && Object.keys(r).length === 0)) {
      // alpha* rules are too restrictive to allow string such as "Addio 2022!"
      continue;
    }
    if (basic.includes(r)) {
      // basic rules
      obj[r] = true;
    } else if (Object.keys(mapped).includes(r)) {
      // mapped rules
      obj[mapped[r]] = true;
    } else if (r.indexOf("integer") != -1) {
      // different rules to be converted
      // laravel integer -> vee-valisate numeric
      obj["numeric"] = true;
    } else if (r.trim().match(minmax)) {
      // min: N, max: N
      let res = minmax.exec(r);
      // if string -> min or max
      if (rules.includes("string")) {
        obj[res[1]] = parseInt(res[2]);
      }
      // if number -> min_value, max_value
      else if (rules.includes("numeric")) {
        obj[`${res[1]}_value`] = parseInt(res[2]);
      }
    } else if (r.trim().match(between)) {
      // { between: { min: 1, max: 20 } }
      // or
      // { between: [1, 20] };
      let res = between.exec(r);
      obj[res[1]] = {
        min: res[2],
        max: res[3],
      };
      // or
      // obj[res[1]] = [ res[2],res[3] ];
    } else if (r.trim().match(requiredif)) {
      let res = requiredif.exec(r);
      // { required_if: { target: 'food', values: [1,5] } }
      obj["required_if"] = {
        target: res[1],
        values: res[2].split(","),
      };
    } else if (r.trim().match(requiredwith)) {
      let res = requiredwith.exec(r);
      // { required_with: @startTime }
      obj["required_with"] = `@${res[1]}`;
    } else if (r.trim().match(afterbefore)) {
      // before: today oppure before: ended_at
      // before_or_equal: today oppure before_or_equal: ended_at
      // after: today oppure after: ended_at
      // after_or_equal: today oppure adter_or_equal: ended_at
      let res = afterbefore.exec(r);
      let inc = ["after", "before"].includes(res[1]) ? false : true;
      let operator = res[1].startsWith("after")
        ? `>${inc ? "=" : ""}`
        : `<${inc ? "=" : ""}`;
      if (res[2] === "today") {
        obj["compare_date"] = {
          otherValue: `${res[2]}`,
          operator: operator,
        };
      } else {
        obj["compare_date"] = {
          otherValue: `@${res[2]}`,
          operator: operator,
        };
      }
    } else if (r.trim().match(op)) {
      let res = op.exec(r);
      let inc = ["gte", "lte"].includes(res[1]) ? true : false;
      let operator = res[1].startsWith("gt")
        ? `>${inc ? "=" : ""}`
        : `<${inc ? "=" : ""}`;
      obj["compare_number"] = {
        otherValue: `${res[2]}`,
        operator: operator,
      };
    } else if (r.trim().match(eq)) {
      let res = eq.exec(r);
      let values = res[2].split(",").map((v) => v.trim());
      switch (res[1]) {
        case "in":
          obj["oneOf"] = values;
          break;
        case "not_in":
          // excluded è built-in, ma funziona solo con gli interi
          // obj["excluded"] = values;
          obj["compare_numbers"] = {
            otherValues: values,
            operator: "!=",
          };
          break;
        default:
          break;
      }
    } else {
      console.info("unimplemented rule:", r);
    }
  }
  return obj;
};

// module.exports = {
//   prepareRules,
// };
