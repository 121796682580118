export default [
  {
    path: "/priv-easy",
    name: "module.PEASY.PRIV",
    meta: {
      auth: true,
      module: "PEASY",
      submodule: "PRIV",
      keepFilter: true, // prevent removeFilterByName on route change. default is false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "priv_easy" */ "@/views/priv-easy/index.vue"),
  },
];
