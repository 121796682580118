const breadcrumbs = {
  namespaced: true,
  state: {
    status: "",
    conf: [],
  },

  mutations: {
    BREADCRUMBS_SAVE(state, { breadcrumbs }) {
      state.conf = breadcrumbs;
      state.status = "saved";
    },
    BREADCRUMBS_REMOVE(state) {
      state.conf = [];
      // console.debug("BREADCRUMBS_REMOVE");
      // console.debug(JSON.stringify(state.conf));
      state.status = "removed";
    },
    BREADCRUMBS_POP(state) {
      // console.debug("BREADCRUMBS_POP");
      // console.debug(JSON.stringify(state.conf));
      state.conf.pop();
      // console.debug(JSON.stringify(state.conf));
      state.status = "popped";
    },
    BREADCRUMBS_ERROR(state) {
      state.status = "error";
    },
  },

  actions: {
    save({ commit }, obj) {
      // console.debug("breadcrumbs action save", obj);
      commit("BREADCRUMBS_SAVE", obj);
    },
    remove({ commit }) {
      // console.debug("breadcrumbs action remove");
      commit("BREADCRUMBS_REMOVE");
    },
    pop({ commit }) {
      commit("BREADCRUMBS_POP");
    },
  },

  getters: {
    loadBreadcrumbs: (state) => {
      //name is a parameter for the getter
      // console.debug("breadcrumbs getter loadBreadcrumbs");
      return state.conf;
    },
  },
};
export default breadcrumbs;
