import moment from "moment";
moment.locale("it");

export const today = (format = "YYYY-MM-DD") => moment().format(format);

export const toDate = (datetime) => {
  // Nel DB:        2021-09-03 03:34:48
  // Nel response:  2021-09-03T01:34:48.000000Z
  if (!datetime) {
    return null;
  }
  return moment(datetime).format("YYYY-MM-DD");
};

export const toLocaleDate = (
  value,
  format = "DD/MM/YYYY",
  defaultValue = ""
) => {
  return value ? moment(value).format(format) : defaultValue;
};

export const fromFirstDay = (date) => {
  if (date == null) return null;
  if (typeof date === "string") {
    // a base-datepicker string: YYYY-MM-DD
    return moment(date).startOf("month").format("YYYY-MM-DD");
  } else {
    // vue-monthly-picker
    // moment
    return date.startOf("month").format("YYYY-MM-DD");
  }
};

export const toLastDay = (date) => {
  if (date == null) return null;
  if (typeof date === "string") {
    // a base-datepicker string: YYYY-MM-DD
    return moment(date).endOf("month").format("YYYY-MM-DD");
  } else {
    // vue-monthly-picker
    // moment
    return date.endOf("month").format("YYYY-MM-DD");
  }
};

export const fromLastDayPreviousMonth = (date) => {
  if (date == null) return null;
  if (typeof date === "string") {
    // a base-datepicker string: YYYY-MM-DD
    return moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
  } else {
    // vue-monthly-picker
    // moment
    return date.subtract(1, "months").endOf("month").format("YYYY-MM-DD");
  }
};

export const calcPolicyDate = (date, interval = "12") => {
  let origin = moment(date);
  let target = moment(date).add(interval, "M");
  let lastDay = moment(date).endOf("month").format("D");
  // if (ultimo giorno di febbraio || mese target più lungo di origin) -> ultimo giorno del mese target
  // if (
  //   (origin.format("M") === "2" && origin.format("D") === lastDay) ||
  //   (origin.format("D") === lastDay &&
  //     origin.daysInMonth() < target.daysInMonth())
  // ) {
  //   target.set("date", moment(target).endOf("month").format("D"));
  // }
  // wallace la vuole scritta così
  if (
    origin.format("D") === lastDay &&
    (origin.format("M") === "2" || origin.daysInMonth() < target.daysInMonth())
  ) {
    target.set("date", moment(target).endOf("month").format("D"));
  }
  return target;
};

export const getAncillaryEffectiveStatus = (
  effectiveDate,
  refDate,
  interval
) => {
  interval = interval != null ? interval : 15;
  let eD = moment(effectiveDate).format("YYYY-MM-DD");
  let rD;
  rD = refDate
    ? moment(refDate).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  // let days = moment(eD).diff(rD, "days");
  let days = moment(rD).diff(eD, "days");
  let ret = 0;
  switch (true) {
    case days > interval: // NEUTRO: valido
      ret = 3; // "white";
      break;
    case days > 0 && days <= interval: // GIALLO: in scadenza
      ret = 2; // "yellow";
      break;
    case days === 0: // ARANCIONE: ultimo giorno
      ret = 1; // "orange";
      break;
    case days < 0: // ROSA: scaduto
      ret = 0; // "pink";
      break;
    default:
      break;
  }
  // // NEUTRO: valido
  // if (days > interval) {
  //   ret =  3;
  // }
  // // GIALLO: in scadenza
  // else if (days > 0 && days <= interval) {
  //   ret =  2;
  // }
  // // ARANCIONE: ultimo giorno
  // else if (days === 0) {
  //   ret =  1;
  // }
  // // ROSA: scaduto
  // else if (days < 0) {
  //   ret =  0;
  // }
  return ret;
};

// module.exports = {
//   today,
//   toDate,
//   fromFirstDay,
//   toLastDay,
//   calcPolicyDate,
//   getAncillaryEffectiveStatus,
// };
