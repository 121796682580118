export default [
  {
    path: "/communication-procedures",
    name: "communication_procedures.index",
    meta: {
      module: "COM",
      auth: true,
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "communications" */ "@/views/communication/procedures/index.vue"
      ),
  },
  {
    path: "/communication-procedures",
    name: "communication_procedures.edit",
    meta: {
      module: "COM",
      auth: true,
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "communications" */ "@/views/communication/procedures/edit.vue"
      ),
  },
  {
    path: "/communication-groupings",
    meta: {
      module: "COM",
      auth: true,
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "communications" */ "@/views/communication/groupings/index.vue"
      ),
  },
  {
    path: "/communication-details",
    meta: {
      module: "COM",
      auth: true,
      submodule: "",
    },

    component: () =>
      import(
        /* webpackChunkName: "communications" */ "@/views/communication/details/index.vue"
      ),
  },
];
