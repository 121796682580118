import axios from "axios";
const re = /(?:www.)?(\w+)(\..*)+/;

const client = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Max-Age": 28800, // Preflight Caching Using Browser for 8 hours
    [process.env.VUE_APP_GATEWAY]: `${re.exec(window.location.hostname)[1]}`,
  },
});

if (process.env.VUE_APP_ENV === 'local') {
  client.defaults.withCredentials = true;
}


export default client;
