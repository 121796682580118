<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFieldsAndActionsMixin from "@/mixins/CustomFieldsAndActionsMixin";
import FilterAndSortMixin from "@/mixins/FilterAndSortMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import {
  joinValues,
  findAndGet,
  toRelationString,
  toInfoData,
  toTooltipTask,
} from "@/utils/transforms";

export default {
  mixins: [
    CustomFieldsAndActionsMixin,
    FilterAndSortMixin,
    ConfirmModalMixin,
    StorageGetterMixin,
  ],
  data() {
    return {
      cacheTimeout: 600000, // 10 minutes in ms
      innerHeight: "",
      innerWidth: "",
      // id: null,
      items: [],
      allIds: [], // hasChecks: the list of all fetched ids
      checkAll: false, // hasChecks: v-model for the check all / uncheck all header control
      selectedRows: [], // hasChecks: the list of selected ids
      totalRows: 0,
      currentPage: 1,
      //   perPage: 0, // set on b-table, means disabled client side pagination.
      perPage: false,
      // pageInitialized: false,
      /**
       // TODO: set perPage from setting, but double check pagination and watch currentPage behavior when perPage is not 0 by default
       // declare the getter:
        ...mapGetters("auth", ["setting"]),
       // set the default value
        perPage: this.setting()("attribute_RPP_value")
       */

      // set here means pagination is managed by server 10,20,50,100
      // pageOptions: [10, 20, 50, { value: 100, text: "Max (100)" }],
      // pageOptions: [
      //   10, 15, 20, 30, 40, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
      //   1000,
      // ],
      pageOptions: [10, 15, 20, 40, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      isLoading: false,
      totalItems: 0,
      // details data
      tabs: [],
      selected: "Undefined", // dynamically load selected component in tabs
      modalResourceId: null, // prop for the dynamic components
      tabIndex: -1, // remember last visited tab: set active tab when modal gets opened
      detailTitle: "",
      skipFilters: ["byRelations", "byView"],
    };
  },
  props: {
    fields: {
      type: Array,
    },
    infomodalName: {
      type: String,
      default: "modal",
    },
    filterName: String,
    repository: String,
    resource: String,
    filterOn: {
      // always filter by this criteria
      type: Object,
      default: () => {},
    },
    onlyActions: {
      type: Array,
      default: () => ["edit", "details", "infomodal", "destroy"],
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    noCustom: {
      type: Boolean,
      default: false,
    },
    noInnerWidth: {
      type: Boolean,
      default: false,
    },
    hasChecks: {
      type: Boolean,
      default: false,
    },
    isCheckExclusive: {
      type: Boolean,
      default: false,
    },
    includeRowSelector: {
      type: Boolean,
      default: false,
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    noInputPaginator: {
      type: Boolean,
      default: false,
    },
    onPagination: {
      type: Object,
      default() {
        return {};
      },
    },
    includeBottomRow: {
      type: Boolean,
      default: false,
    },
    sortField: {
      type: String,
      default: "",
    },
    descending: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  computed: {
    modalTitle() {
      // return this.tabs.length
      //   ? `Dettaglio ${
      //       this.tabs.find((elem) => elem.name === this.selTab).text
      //     }`
      //   : "";
      return this.tabs.length ? `Dettagli ${this.detailTitle}` : "";
    },
    height: {
      get() {
        return this.innerHeight;
      },
      set(val) {
        this.innerHeight = val;
      },
    },
    width: {
      get() {
        return this.innerWidth;
      },
      set(val) {
        this.innerWidth = val;
      },
    },
    getAllFields() {
      let ret = this.allFields;
      if (this.noActions) {
        ret = ret.filter((elem) => elem.key !== "actions");
      }
      if (!this.includeRowSelector) {
        ret = ret.filter((elem) => elem.key !== "rowSelector");
      }
      return ret;
    },
    selTab: {
      set(value) {
        this.selected = value;
      },
      get() {
        return this.selected;
      },
    },
    ...mapGetters("auth", ["permissions"]),
  },
  mounted() {
    // console.log(`%c MOUNTED`, "color: blue");
    this.resize();
    this.sortBy = this.sortField;
    this.sortDesc = this.descending;
    // if there is a stored filter or some filterOn values ( except for just 1 filterOn which equals "byRelations" ),
    // then can go auto fetch
    // if (
    //   this.loadFilter(this.filterName) ||
    //   (this.filterOn &&
    //     Object.keys(this.filterOn).length &&
    //     !(
    //       Object.keys(this.filterOn).length === 1 &&
    //       Object.keys(this.filterOn)[0] === "byRelations"
    //     ))
    // ) {
    let filter = this.loadFilter(this.filterName);
    if (
      filter
      // ||
      // (this.filterOn &&
      //   Object.keys(this.filterOn).length &&
      //   !Object.keys(this.filterOn).some(
      //     (key) => !this.skipFilters.includes(key)
      //   )) // nessuna key non sta in skipFilters (o se vuoi, tutte le key stanno in skipFilters)
    ) {
      console.info("AUTOMATIC FETCH");
      this.fetch().then(() => {
        this.clearRoute();
      });
    } else {
      this.clearRoute();
    }
  },
  created() {
    // console.log(`%c CREATED`, "color: orange");
    window.addEventListener("resize", this.resize);
    this.resize();
    /* */
    console.log("created template table");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {
            console.info(`background data "${path}reports" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("insurers"))
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {
            console.info(`background data "${path}insurers" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    joinValues,
    findAndGet,
    toRelationString,
    toInfoData,
    toTooltipTask,
    moment,
    ...mapGetters("sidebar", ["isNavClose"]),
    // ...mapGetters("auth", ["setting"]),
    ...mapActions("cache", {
      saveCacheByName: "saveByName",
      removeCacheByName: "removeByName",
    }),
    ...mapGetters("cache", { getCacheByName: "get" }),

    resize() {
      // this.height = window.innerHeight - 350 + "px";
      const extraFooter = !document.querySelector(
        ".component-fade-enter-active .table-extra-footer"
      )
        ? 0
        : [
            ...document.querySelectorAll(
              ".component-fade-enter-active .table-extra-footer"
            ),
          ].reduce((acc, item) => {
            return acc + item.clientHeight;
          }, 0);
      const tabsOffset = document.querySelector(
        ".component-fade-enter-active .tabs"
      )
        ? 35 + 4
        : 0;
      this.height = `${
        window.innerHeight -
        tabsOffset -
        extraFooter -
        48 -
        26 -
        4 -
        36 -
        29 -
        8 -
        16 -
        47
      }px`;
      // this.width = window.innerWidth - 90 + "px";
      // console.log(`%c TABS: ${tabsOffset}`, "color: green");
      // console.log(`%c EXTRA FOOTER: ${extraFooter}`, "color: green");
      // console.log(`%c HEIGHT: ${this.height}`, "color: green");
      // console.debug("width:", this.width);
    },
    clearRoute() {
      this.$nextTick(function () {
        // Code that will run only after the entire view has been rendered
        // query: {click: 'openModal', id: 1, modalName: 'modal', hash: '#General'}
        // console.debug("$$$ SHORTCUT");
        if (this.tabs.length && this.$route.query.hash) {
          // console.debug("$$$ this.$route.query.hash: ", this.$route.query.hash);
          this.tabIndex = this.tabs.findIndex(
            (tab) => tab.name === this.$route.query.hash.slice(1)
          ); // remove # from route.hash

          if (this.tabIndex !== -1) {
            // console.debug("$$$ tabIndex: ", this.tabIndex);
            this.selected = this.tabs[this.tabIndex].name;
          }
        }
        if (this.$route.query && this.$route.query.click) {
          // console.debug("$$$ this.$route.query: ", this.$route.query);
          this[this.$route.query.click]({ id: this.$route.query.id });
        }

        // clear the url (remove hash and query that have been set by shortcut)
        // console.debug(`hash: ${this.$route.hash}, query: ${Object.keys(this.$route.query).length}, (hash || query): ${(this.$route.hash || Object.keys(this.$route.query).length)}`)
        if (this.$route.hash || Object.keys(this.$route.query).length) {
          // console.debug("$$$ router.replace() $$$")
          this.$router.replace({ hash: null, query: null });
        }
      });
    },
    onModalHidden() {
      console.debug("onModalHidden");
      // this.selected = {name: "General", text: "Generale"}
    },
    onSelectRow(value, index, item) {
      // value == value of checkbox (ie. true or false, or whatever is stored in v-model)
      // index == visual index of row (i.e. the index of the row wrt the displayed rows)
      // item == the clicked row item data
      // console.debug("onSelectRow");
      // console.debug(value, index, item, filterName);
      // if (this.isCheckExclusive == true) {
      //   console.debug("checkExclusive: true");
      // } else if (this.isCheckExclusive == false) {
      //   console.debug("checkExclusive: false");
      // } else {
      //   console.debug("checkExclusive: null");
      // }

      if (item.check) {
        if (this.isCheckExclusive) {
          // empty array
          this.selectedRows = [];
          // unselect other evetually selected
          this.unselectAll();
          // reselect this one
          this.items.find((e) => e.id === item.id).check = true;
        }
        // push
        this.selectedRows.push(item.id);
      } else {
        // pop
        this.selectedRows = this.selectedRows.filter((i) => i !== item.id);
      }
      // this sets the global checkbox status (remove if use buttons)
      if (this.selectedRows.length === 0) {
        this.checkAll = false;
      } else if (this.selectedRows.length === this.allIds.length) {
        this.checkAll = true;
      } else if (this.selectedRows.length !== this.allIds.length) {
        this.checkAll = false;
      }
    },
    onSelectAllRows() {
      // this.checkAll ? this.$emit('selectAll') : this.$emit('unselectAll');
      this.checkAll ? this.selectAll() : this.unselectAll();
    },
    selectAll() {
      // this.$refs[this.tableRef].selectedRows = this.$refs[this.tableRef].allIds;
      let extendedData = this.items.map((obj) => ({
        ...obj,
        check: true,
      }));
      this.items = extendedData;
    },
    unselectAll() {
      // this.$refs[this.tableRef].selectedRows = [];
      let extendedData = this.items.map((obj) => ({
        ...obj,
        check: false,
      }));
      this.items = extendedData;
    },
    // da input ( base-input-pagination )
    onInputPagination(val) {
      if (!isNaN(val) && val != 0) {
        this.handleOnPagination(val);
        // if (!proceed) {
        //   return;
        // }
        // this.currentPage = val;
        // let filter = this.keepFilter();
        // this.fetch().then(() => {
        //   if (filter) {
        //     let name = this.filterName;
        //     this.removeFilterByName(name);
        //   }
        // });
      }
    },
    // da paginatore ( « ‹ 1 2 3 › » )
    onPageChange(val) {
      if (!isNaN(val) && val != 0) {
        this.handleOnPagination(val);
        // if (!proceed) {
        //   return;
        // }
        // this.currentPage = val;
        // let filter = this.keepFilter();
        // this.fetch().then(() => {
        //   if (filter) {
        //     let name = this.filterName;
        //     this.removeFilterByName(name);
        //   }
        // });
      }
    },
    fetchPage(val) {
      // cambio pagina
      this.currentPage = val;
      let filter = this.keepFilter();
      let args = this.$parent?.$parent?.args ? this.$parent?.$parent?.args : {};

      this.fetch(args).then(() => {
        if (filter) {
          let name = this.filterName;
          this.removeFilterByName(name);
          this.removePaginationByName(name);
        }
      });
    },
    handleOnPagination(val) {
      if (Object.keys(this.onPagination).length) {
        switch (this.onPagination.action) {
          case "confirm":
            if (this.onPagination.checkCondition) {
              this.showConfirm({
                yesCallback: () => {
                  if (this.onPagination.yesCallback) {
                    this.onPagination.yesCallback().then(() => {
                      // cambio pagina
                      this.fetchPage(val);
                    });
                  }
                  // return true;
                },
                noCallback: () => {
                  if (this.onPagination.noCallback) {
                    this.onPagination.noCallback();
                  }
                  // cambio pagina
                  this.fetchPage(val);
                },
                title: this.onPagination.title,
                message: this.onPagination.message,
                yesLabel: this.onPagination.yesLabel,
                yesVariant: this.onPagination.yesVariant,
                noLabel: this.onPagination.noLabel,
                noVariant: this.onPagination.noVariant,
              });
            } else {
              // cambio pagina
              this.fetchPage(val);
            }
        }
      } else {
        // cambio pagina
        this.fetchPage(val);
      }
    },
    fetch(args = {}) {
      // args = { items: { handle: null, params: [] }, verb: { name: null, params: [] } , options: { noPagination: false }, extra: { run: function }};
      if (this.args) {
        args = this.args;
      }
      const useCache = process.env.VUE_APP_CACHE == 1;
      console.info("FETCH");
      // console.debug("currentPage:", this.currentPage);
      // console.debug("perPage:", this.perPage);
      const Repo = RepositoryFactory.get(this.repository);
      if (!Repo) {
        let errorMsg = `Unknown Repo: ${this.repository}`;
        console.error(errorMsg);
        this.$showSnackbar({
          preset: "error",
          showAction: false,
          text: errorMsg,
        });
        return;
      }
      const name = this.filterName;
      this.isLoading = true;
      // this way, QueryString has items in the same order below after join("&")
      let qs = {};
      // 0. Pagination
      let pagination = "perPage=0";
      if (this.noPagination || (args.options && args.options.noPagination)) {
        qs.pagination = pagination;
      } else {
        // load page data, if any
        pagination = this.loadPagination(name);
        if (pagination) {
          qs.pagination = pagination;
        } else {
          qs.pagination = `page=${this.currentPage}${
            this.perPage ? "&perPage=" + this.perPage : ""
          }`;
        }
      }
      // 1. filterOn, if any
      if (this.filterOn && Object.keys(this.filterOn).length) {
        let filterOn = this.prepareFilterQueryString(this.filterOn);
        if (filterOn) {
          qs.filterOn = filterOn;
        }
      }
      // 2. filter data, if any
      let filter = this.loadFilter(name);
      if (filter) {
        qs.filter = filter;
      }
      // 3. sort data, if any
      let sort = this.loadSort(name);
      if (sort) {
        qs.sort = sort;
      }
      let queryString = Object.values(qs).join("&");
      // console.debug("QS: ", qs);
      // console.debug(`QUERY STRING: ${queryString}`);

      // IDEA: implement data cache
      // try to load data from a new vuex store "cache", and if match (*):
      // - skip 'call'
      // - do some things in the original then clause (set perPage, totalItems, this.items, etc...)
      // 'call', on the other hand, always saves response.data (not just response.data.data) in cache object:
      // {
      //   repository: this.repository,
      //   qs: qs,
      //   data: response.data
      // }
      // OCIO (*) THE HARD TASK is find/write a compare function between objects
      let cache = this.getCacheByName()(name, qs);
      if (
        useCache &&
        cache &&
        moment().diff(moment(cache.time, "YYYY-MM-DD HH:mm")) <
          this.cacheTimeout
      ) {
        return new Promise((resolve) => {
          const totalItems = cache.totalItems;
          const perPage = cache.perPage;
          if (this.hasChecks) {
            // set the allIds:
            this.allIds = cache.items.map((i) => i.id);
            // add check prop to data
            // data = data.map((obj) => ({ ...obj, check: false }));
            //emptyselectedRows
            this.selectedRows = [];
            this.checkAll = false;
          }

          this.items = cache.items;
          // Set the number of items
          this.totalRows = this.items.length;
          this.totalItems = totalItems;
          if (parseInt(this.perPage) != parseInt(perPage)) {
            // console.info("perPage CAMBIATO");
            this.perPage = perPage;
          }
          this.isLoading = false;
          resolve();
        });
      } else {
        // verb handle as a replacement for index
        const verbParams =
          args.verb && args.verb.params ? args.verb.params : [queryString];

        // const call = (qs, prms) =>
        //   args.verb && args.verb.name
        //     ? Repo[args.verb.name](qs, prms)
        //     : Repo.index(qs, prms);
        const verb = args.verb && args.verb.name ? args.verb.name : "index";
        // check permission for the "verb"...
        const checkVerb =
          args.verb && args.verb.name
            ? args.verb.alias
              ? args.verb.alias
              : args.verb.name
            : "index";
        console.log("verb: ", verb);
        console.log("resource: ", this.resource);
        const canFetch = this.permissions.includes(
          `resources.${this.resource}.${checkVerb}`
        );
        console.log("permission: ", canFetch);
        if (!canFetch) {
          let errMsg = `Non hai il permesso di eseguire '${verb}' sulla risorsa ${this.resource}`;
          return new Promise((resolve) => {
            this.isLoading = false;
            this.items = [];
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            resolve();
          });
        }

        if (
          args.extra &&
          args.extra.runBefore &&
          args.extra.runBefore instanceof Function
        ) {
          args.extra.runBefore();
        }
        // const call = (params) =>
        //   verb ? Repo[verb](...params) : Repo.index(...params);
        const call = (params) => Repo[verb](...params);

        // return call(queryString, ...verbParams)
        return call(verbParams)
          .then((response) => {
            let data = response.data.data;
            let totalItems = response.data.total;
            const perPage = response.data.per_page;
            //console.debug(`data length ${this.repository}: ${data.length}`);
            // console.debug(`totalItems ${totalItems}`);
            // console.debug(`this.perPage ${this.perPage}`);
            // console.debug(`perPage ${perPage}`);
            // console.debug("hasChecks:", this.hasChecks);

            // Set the items
            const handleParams =
              args.items && args.items.params ? args.items.params : [];
            if (
              args.items &&
              args.items.handle &&
              args.items.handle instanceof Function
            ) {
              // items handle
              data = args.items.handle(data, ...handleParams);
              // se handle effettua filter o cmq altera il count dei dati...
              if (!args?.items?.filtered) {
                totalItems = response.data.total;
              } else {
                totalItems = data.length;
              }
            }

            if (this.hasChecks) {
              // set the allIds:
              this.allIds = data.map((i) => i.id);
              // add check prop to data
              data = data.map((obj) => ({ ...obj, check: false }));
              //emptyselectedRows
              this.selectedRows = [];
              this.checkAll = false;
            }

            this.items = data;
            // Set the number of items
            this.totalRows = this.items.length;
            this.totalItems = totalItems;
            if (parseInt(this.perPage) != parseInt(perPage)) {
              // console.info("perPage CAMBIATO");
              this.perPage = perPage;
            }
            if (useCache) {
              cache = {
                repository: this.repository,
                qs: qs,
                items: data,
                totalItems: response.data.total,
                perPage: response.data.per_page,
                time: moment().format("YYYY-MM-DD HH:mm"),
              };
              this.saveCacheByName({ name, cache });
            }
            // if (args.extra && args.extra.runBefore) {
            //   args.extra.runBefore();
            // }
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            // showSnackbar({ preset: "error", text: error });
            // clear data
            this.items = [];
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setDetailTitle(item) {
      switch (this.repository) {
        case "registry":
          this.detailTitle =
            item.status_registry.value == 1
              ? `- ${item.attributables.CNAM} ${item.attributables.CREG}`
              : `- ${item.attributables.SURN} ${item.attributables.NAME}`;
          break;
        case "insurance_policy":
          this.detailTitle = `- Numero Polizza: ${item.number}`;
          break;
        case "claim":
          this.detailTitle = `- Numero Sinistro: ${item.number}`;
          break;
        case "agency_contact":
          this.detailTitle = item.attributables.NAME
            ? `- ${item.attributables.SURN}  ${item.attributables.NAME}`
            : `- ${item.attributables.CNAM}`;
          break;
        case "broker":
          this.detailTitle = item.attributables.NAME
            ? `- ${item.attributables.SURN} ${item.attributables.NAME}`
            : `- ${item.attributables.CNAM} ${item.attributables.CREG}`;
          break;
        case "sector":
          this.detailTitle = `${item.title} - ${item.display}`;
          break;
        default:
          this.detailTitle = "";
          break;
      }
    },
    openModal(item, index, button) {
      // SHORTCUT (Calendar)
      console.log(this.$route);
      if (this.$route.params.data) {
        item = this.$route.params.data;
        this.setDetailTitle(item);
      }
      if (!(Object.keys(item).length === 1 && Object.keys(item)[0] === "id")) {
        this.setDetailTitle(item);
      }
      this.modalResourceId = item.id;
      // console.debug("modalResourceId: ", this.modalResourceId);
      // NEW: sometimes db row item should be available for furter usage...
      if (Object.hasOwnProperty.call(this, "dbRowData")) {
        this.dbRowData = item;
      }
      this.$bvModal.show(this.infomodalName, button);
    },
    closeModal() {
      // chiude la modale
      this.$bvModal.hide(this.infomodalName);
    },
    setTab(tab, index) {
      console.log("select tab: ", tab.name);
      this.selTab = tab.name;
      this.tabIndex = index;
    },
    onEdit(id, item) {
      // console.debug("input onEdit", id);
      this.$emit("edit", id, item);
    },
    onDestroy(id, item) {
      this.showConfirm({
        yesCallback: () => {
          this.$emit("destroy", id, item);
        },
        noCallback: null,
        title: "Conferma Eliminazione",
        message: "Vuoi davvero eliminare?",
        yesLabel: "ELIMINA",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    onRestore(id) {
      this.showConfirm({
        yesCallback: () => {
          this.$emit("restore", id);
        },
        noCallback: null,
        title: "Conferma Ripristino",
        message: "Vuoi davvero ripristinare la visibilità del campo?",
        yesLabel: "RIPRISTINA",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    onHide(id, hidden) {
      if (hidden) {
        this.showConfirm({
          yesCallback: () => {
            this.$emit("restore", id);
          },
          noCallback: null,
          title: "Conferma Ripristino",
          message: "Vuoi davvero ripristinare la visibilità del campo?",
          yesLabel: "RIPRISTINA",
          noLabel: "ANNULLA",
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      } else {
        this.showConfirm({
          yesCallback: () => {
            // this.$emit("destroy", id); //was last_delete
            this.$emit("hide", id);
          },
          noCallback: null,
          title: "Conferma Eliminazione",
          message: "Vuoi davvero eliminare la visibilità del campo?",
          yesLabel: "ELIMINA",
          noLabel: "ANNULLA",
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      }
    },

    onChosen(item, handler = "rowSelector") {
      this.$emit(handler, item);
    },
    // customized(item) {
    //   return {}
    //   // let c = {};
    //   // // TODO: customize
    //   // c.value = item.value

    //   // return c;
    // },
    onCellChange(id) {
      this.$emit("cellChange", id);
    },
    // totalizeFields(data, key) {
    //   let hasChecks = this.hasChecks;
    //   let parts = key.split(".");
    //   return data
    //     .map((row) => {
    //       // somma solo se in presenza dei check, sono checkati
    //       return ( !hasChecks || (hasChecks && row.check) ) ? +parts.reduce((acc, part) => acc && acc[part], row) : 0;
    //     })
    //     .reduce((sum, i) => {moment
    //       return sum + i;
    //     }, 0)
    // },
    // totalQuantity(data, key) {
    //   let hasChecks = this.hasChecks;
    //   let parts = key.split(".");
    //   return data
    //     .map((row) => {
    //       // somma solo se in presenza dei check, sono checkati
    //         return ( !hasChecks || (hasChecks && row.check) ) ? +parts.reduce((acc, part) => acc && acc[part], row) : 0;
    //     })
    //     .reduce((sum, i) => {
    //       return sum + i;
    //     }, 0);
    // },
    totalizeFields(data, fields) {
      let hasChecks = this.hasChecks;
      let tot = 0;
      if (typeof fields == "string") {
        fields = [fields];
      }
      fields.forEach((field) => {
        let parts = field.split(".");
        tot += data
          .map((row) => {
            // somma solo se in presenza dei check, sono checkati
            return !hasChecks || (hasChecks && row.check)
              ? +parts.reduce((acc, part) => acc && acc[part], row)
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
  },
  // watch: {
  //   currentPage: {
  //     handler: function (value) {
  //       console.debug("currentPage:", value, this.currentPage);
  //       console.debug("perPage:", this.perPage);
  //       // if (parseInt(+this.perPage) !== 0) {
  //       if (this.perPage !== false) {
  //         this.fetch();
  //       }
  //     },
  //   },
  // },
};
</script>
<style lang="scss" scoped>
// :deep(th.position-relative) {
//   position: sticky !important;
// }
// :deep(.b-table-sticky-header) {
//   > .table.b-table
//   > thead
//   > tr
//   > th.position-relative {
//   position: sticky !important;
// }
</style>
