export default [
  {
    path: "/import",
    name: "importers.index",
    meta: {
      auth: true,
      module: "IMP",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "import" */ "@/views/importer/Management.vue"
      ),
  },
];
