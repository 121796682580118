export default [
  {
    path: "/log-email",
    name: "module.LOG.LEMA",
    meta: {
      auth: true,
      module: "LOG",
      submodule: "LEMA",
    },
    component: () =>
      import(/* webpackChunkName: "log" */ "@/views/log/email/index.vue"),
  },
  {
    path: "/log-sms",
    name: "module.LOG.LSMS",
    meta: {
      auth: true,
      module: "LOG",
      submodule: "LSMS",
    },

    component: () =>
      import(/* webpackChunkName: "log" */ "@/views/log/sms/index.vue"),
  },
  {
    path: "/log-hybryd-mail",
    name: "module.LOG.LIBR",
    meta: {
      auth: true,
      module: "LOG",
      submodule: "LIBR",
    },
    component: () =>
      import(/* webpackChunkName: "log" */ "@/views/log/hybrid-mail/index.vue"),
  },
  {
    path: "/log-import",
    name: "import",
    meta: {
      auth: true,
      hasAccess: true,
      module: "LOG",
      submodule: "",
    },
    component: () =>
      import(/* webpackChunkName: "log" */ "@/views/log/import/index.vue"),
  },
  {
    path: "/log-broker-statement",
    name: "broker_statement",
    meta: {
      auth: true,
      hasAccess: true,
      module: "LOG",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "log" */ "@/views/log/broker-statement/index.vue"
      ),
  },
];
