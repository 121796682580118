import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/communication_groupings";
export default {
  create() {
    // get the create form
    return Repository.get(`${resource}/create`);
  },
  edit(id) {
    // get the edit form
    return Repository.get(`${resource}/${id}/edit`);
  },
  validate(field, payload) {
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    // delete the item
    return Repository.delete(`${resource}/${id}`);
  },
  specifications(
    salesman_id,
    insurer_id,
    risk_branch_id = null,
    insurance_risk_id = null
  ) {
    // show pivot data by salesman (id) ....
    if (risk_branch_id === null && insurance_risk_id === null) {
      // ...filtered by insurer (insurer_id)
      //  level 1
      return Repository.get(
        `risk_branches?perPage=0&bySpecification[id]=${salesman_id}&bySpecificationInsurer[id]=${insurer_id}&byInsurer[id]=${insurer_id}`
      );
    } else if (insurance_risk_id === null) {
      // ...filtered by insurer (insurer_id) and branch (risk_branch_id)
      // level 2
      // return Repository.get(
      //   `insurance_risks?perPage=0&bySpecification[id]=${salesman_id}&bySpecificationInsurer[id]=${insurer_id}&byInsurer[id]=${insurer_id}&byRiskBranch[id]=${risk_branch_id}`
      // );
      // @Sergio in più, nell'elenco dei rischi, devi togliere il filtro nuovo bySpecificationInsurer che non serve perché quelle sono righe di prodotti e c'è già il filtro deidcato
      return Repository.get(
        `insurance_risks?perPage=0&bySpecification[id]=${salesman_id}&byInsurer[id]=${insurer_id}&byRiskBranch[id]=${risk_branch_id}`
      );
    } else {
      // ...filtered by product (insurance_risk_id)
      // level 3
      // return Repository.get(
      //   `breakdowns?perPage=0&bySpecification[id]=${salesman_id}&bySpecificationInsurer[id]=${insurer_id}&byInsuranceRisk[id]=${insurance_risk_id}`
      // );
      return Repository.get(
        `breakdowns?perPage=0&bySpecification[id]=${salesman_id}&byInsuranceRisk[id]=${insurance_risk_id}`
      );
    }
  },
  create_with(outsource, id) {
    return Repository.get(`${resource}/create/${outsource}/${id}`);
  },
  pivot_form(id, relation) {
    return Repository.get(`${resource}/${id}/pivot/${relation}`);
  },
  pivot_destroy(id, relation, payload) {
    return Repository.delete(`${resource}/${id}/pivot/${relation}`, {
      data: payload,
    });
  },
  pivot_store(id, relation, payload) {
    return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
  },
  pivot_update(id, relation, payload) {
    return Repository.put(`${resource}/${id}/pivot/${relation}`, payload);
  },
};
