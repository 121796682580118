const lisacomunica_automodal = {
  namespaced: true,
  state: {
    status: "",
    // Usato nel salto/passaggio dal quietanzamento al modulo Lisa Comunica, una volta premuto
    // sul pulsante "Invia selezionati"
    showAutoModal: false,
  },

  mutations: {
    LISACOMUNICA_AUTOMODAL_SAVE(state, value) {
      state.showAutoModal = value;
      state.status = "saved";
    },
  },

  actions: {
    saveLisacomunicaAutoModal({ commit }, value) {
      commit("LISACOMUNICA_AUTOMODAL_SAVE", value);
    },
  },
  getters: {
    isShowAutoModal: (state) => {
      return state.showAutoModal;
    },
  },
};

export default lisacomunica_automodal;
