// import { showSnackbar } from "@/utils/messages";
export default {
  methods: {
    showConfirm(args = {}) {
      // all args:
      // yesCallback
      // noCallback
      // title
      // message
      // yesLabel
      // noLabel
      // yesVariant
      // noVariant
      // headerBgVariant
      let params = {};
      // common args both for custom or predefined snackbar
      params.title = args.title ? args.title : "Conferma";
      params.message = args.message
        ? args.message
        : "Sei sicuro di voler proseguire?";
      params.yesVariant = args.yesVariant ? args.yesVariant : "outline-danger";
      params.noVariant = args.noVariant ? args.noVariant : "outline-secondary";
      params.headerBgVariant = args.headerBgVariant
        ? args.headerBgVariant
        : "bg-lisaweb";
      params.yesLabel = args.yesLabel ? args.yesLabel : "Sì";
      params.noLabel = args.noLabel ? args.noLabel : "No";
      if (args.yesCallback) {
        params.yesCallback = () => {
          args.yesCallback();
        };
      }
      if (args.noCallback) {
        params.noCallback = () => {
          args.noCallback();
        };
      }
      this.confirm = "";
      this.$bvModal
        .msgBoxConfirm(params.message, {
          title: params.title,
          size: "sm",
          buttonSize: "sm",
          okVariant: params.yesVariant,
          cancelVariant: params.noVariant,
          okTitle: params.yesLabel,
          cancelTitle: params.noLabel,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: false,
          headerClass: params.headerBgVariant,
        })
        .then((value) => {
          if (value === true) {
            if (params.yesCallback) {
              params.yesCallback();
              // showSnackbar({
              //   preset: "sucess",
              //   text: `Operazione Eseguita`,
              // });
            } else {
              this.$showSnackbar({
                preset: "info",
                text: `Nessuna operazione eseguita`,
              });
            }
          } else {
            if (params.noCallback) {
              params.noCallback();
            } else {
              this.$showSnackbar({
                preset: "info",
                text: `Operazione Annullata`,
              });
            }
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
};
