import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/insurance_payments";
export default {
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  create() {
    // get the create form
    return Repository.get(`${resource}/create`);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  edit(id) {
    // get the edit form
    return Repository.get(`${resource}/${id}/edit`);
  },
  history(id) {
    return Repository.get(`${resource}/${id}/history`);
  },
  bulk_destroy(payload) {
    // delete the item
    if (payload) {
      return Repository.delete(`${resource}/bulk/delete`, {
        data: payload,
      });
    }
  },
  filter_update(payload, queryString) {
    return queryString
      ? Repository.put(`${resource}/filter/update?${queryString}`, payload)
      : Repository.put(`${resource}/filter/update`, payload);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  pivot_store(id, relation, payload) {
    return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
  },
  pivot_update(id, relation, payload) {
    return Repository.put(`${resource}/${id}/pivot/${relation}`, payload);
  },
};
